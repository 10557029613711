import React, { useEffect, Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { Grid, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import QrReader from 'modern-react-qr-reader';
import { Alert } from 'reactstrap';
import { $http, public_url, base_url, WEBSITE_URL } from 'config';
import { EmptyImage } from 'constant/Component';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import { fetchError, fetchSuccess } from 'redux/actions';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ScanPage = () => {
  // const requestedUrl = match.url.replace(/\/$/, '');
  const { authUser } = useSelector(({ auth }) => auth);

  // const { is_scan } = useSelector(({ other }) => other);
  const [scan_step, setScanStep] = useState(1);
  const [product, setProduct] = useState(null);
  const [scan_success, setScanSuccess] = useState(false);
  const [sites, setSites] = useState([]);
  const [site_rooms, setSiteRooms] = useState([]);
  const [site, setSite] = useState(0);
  const [room, setRoom] = useState(0);
  const [scan_products, setScanProducts] = useState([]);
  const [checkin_status, setCheckinStatus] = useState(0);

  const dispatch = useDispatch();

  const handleScan = result => {
    console.log('result: ', result);
    if (result) {
      setScanSuccess(true);
      const data = JSON.parse(result);
      if (data.app_name == 'sirvez') {
        let id = `product_id=${data.product_id}`;
        if (!data.product_id) id = `group_id=${data.group_id}`;
        $http.get(`${base_url}product/getScanProduct?${id}`).then(response => {
          setSites(response.data.sites);
          setSiteRooms(response.data.site_rooms);
          if (data.product_id) {
            setScanProducts([...scan_products, response.data.product]);
            setProduct(response.data.product);
          } else {
            setProduct(response.data.product[0]);
            setScanProducts([...scan_products, ...response.data.product]);
          }

          setScanStep(2);
        });
      }
    }
  };

  const handleError = err => {
    console.log('err===> ', err);
  };

  const handleCheckIn = () => {
    if (site == 0 || room == 0) return;
    setCheckinStatus(1);
    const frmData = new FormData();
    frmData.append('scan_products', JSON.stringify(scan_products.map(item => item.id)));
    frmData.append('to_site_id', site);
    frmData.append('to_room_id', room);
    $http
      .post(`${base_url}product/scanProducts`, frmData)
      .then(response => {
        if (response.data.status == 'success') {
          dispatch(fetchSuccess('The products was scanned successfully.'));
          setCheckinStatus(2);
        } else if (response.data.status == 'error') {
          dispatch(fetchError('500 Error'));
          setCheckinStatus(0);
        }
      })
      .catch(error => dispatch(fetchError(error)));
  };

  const handleMultiCheckin = () => {
    setScanStep(1);
    setCheckinStatus(0);
    setProduct(null);
  };

  const handleShowProductDetail = () => {
    window.open(`${WEBSITE_URL}product/${product.id}`);
  };

  return (
    <Suspense fallback={<PageLoader />}>
      <PageContainer>
        <GridContainer>
          <Grid item xs={12} className="d-center">
            {scan_step == 1 ? (
              <div className="bg-white d-flex align-items-center flex-column p-5">
                <QrReader
                  delay={300}
                  facingMode={'environment'}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
                <Alert className="shadow-lg mt-3 mb-0 font-3" color={scan_success ? 'success' : 'primary'}>
                  {scan_success ? 'Scan Success' : 'QR code scanning...'}
                </Alert>
              </div>
            ) : (
              <div className="mui-panel col-md-6">
                <div className="mui-panel-content">
                  <div className="activity feed-line d-flex align-items-center">
                    <div className="list-item">
                      <figure className="scan-product-img">
                        {product.product_img ? (
                          <img src={`${public_url}upload/img/${product.product_img}`} alt={product.customer_name} />
                        ) : (
                          <EmptyImage />
                        )}
                      </figure>
                      <div className="activity-text font-5">
                        {`${product.product_name}`}
                        <div>
                          <span className="date">#{product.id}</span>
                          {product.group_id != 0 && (
                            <>
                              <span className="font-2 ml-2">GroupID: </span>
                              <span className="date">#{product.group_id}</span>
                              <span className="font-2 ml-2">
                                Qty:{' '}
                                {`${scan_products.filter(item => item.group_id == product.group_id).length}/${
                                  product.group_cnt
                                }`}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <label className="mt-3 font-6">Where are you?</label>
                  </div>
                  <div className="form-outline mt-3">
                    <NativeSelect
                      value={site}
                      onChange={event => setSite(event.target.value)}
                      input={<BootstrapInput />}
                      fullWidth>
                      <option value={0}>Site?</option>
                      {sites.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.site_name}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                  <div className="form-outline mt-3">
                    <NativeSelect
                      value={room}
                      onChange={event => setRoom(event.target.value)}
                      input={<BootstrapInput />}
                      fullWidth>
                      <option value={0}>Location?</option>
                      {site_rooms
                        .filter(item => item.site_id == site)
                        .map(item => (
                          <option key={item.id} value={item.id}>
                            {item.room_number}
                          </option>
                        ))}
                    </NativeSelect>
                  </div>

                  <div className="font-4 mt-2">
                    {authUser.user_type < 5 ? (
                      <div
                        className="d-flex justify-content-center align-items-center border-bottom py-2 text-link"
                        onClick={handleCheckIn}>
                        {checkin_status == 0 ? (
                          <i className="sir sir-icon-check-in mr-2 icon-large" />
                        ) : checkin_status == 1 ? (
                          <i className="spinner-loading size-small mr-2 icon-large" />
                        ) : (
                          <i className="siz siz-icon-check mr-2 icon-large" />
                        )}
                        {`${checkin_status == 0 ? 'Check in' : checkin_status == 1 ? 'Checking...' : 'Checked In'}`}
                      </div>
                    ) : null}
                    {checkin_status != 2 ? (
                      <div
                        className="d-flex justify-content-center align-items-center border-bottom py-2 text-link"
                        onClick={handleMultiCheckin}>
                        <i className="siz siz-icon-signin1 mr-2 icon-large"></i> Check in +1
                      </div>
                    ) : null}
                    <div className="d-flex cat-text justify-content-center my-2" onClick={handleShowProductDetail}>
                      See product info
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </GridContainer>
      </PageContainer>
    </Suspense>
  );
};

export default ScanPage;
