import { $http, public_url, mobile_url, CACHE_NAME, local_ip } from 'config';
import axios from 'axios';
import { TaskPriorityRows, COMPANY_LOGO_URL, FROM_NETWORK, FROM_CACHE } from 'constant/Constant';
import localforage from 'localforage';

const BLANK_IMG = require(`assets/images/150.png`);
const BLANK_IMG1 = require(`assets/images/add-image-icon.jpg`);
// const dispatch = useDispatch();

const getResponsePWA = (fetch_url, updateNetworkResponse, failedNetworkResponse = undefined) => {
  // dispatch(fetchStart());
  $http
    .get(fetch_url)
    .then(response => {
      // console.log('from network', fetch_url, response.data);
      updateNetworkResponse(response.data, FROM_NETWORK);
    })
    .catch(error => {
      if (typeof failedNetworkResponse === 'function') {
        failedNetworkResponse(error);
      } else {
        // dispatch(fetchError(error));
      }
    });

  if (window.location.hostname === local_ip) return;
  caches.open(CACHE_NAME).then(function (cache) {
    cache
      .match(fetch_url)
      .then(function (response) {
        return response ? response.json() : null;
      })
      .then(function (data) {
        if (!data) return;
        // console.log('from cache', fetch_url, data);
        updateNetworkResponse(data, FROM_CACHE);
      });
  });
};

const getResponseServer = (fetch_url, updateNetworkResponse, failedNetworkResponse = undefined) => {
  // dispatch(fetchStart());
  $http
    .get(fetch_url)
    .then(response => {
      // console.log('from network', fetch_url, response.data);
      updateNetworkResponse(response.data, FROM_NETWORK);
    })
    .catch(error => {
      if (typeof failedNetworkResponse === 'function') {
        failedNetworkResponse(error);
      } else {
        // dispatch(fetchError(error));
      }
    });
};

// const { authUser } = useSelector(({ auth }) => auth);
const authUser = JSON.parse(localStorage.getItem('user'));

const isProjectStep = (project, step_type) => {
  if (step_type === 0) {
    // all stage
    return true;
  } else if (step_type === 1) {
    // Prospect stage
    return project.archived == 0 && project.signed_off == 0;
  } else if (step_type === 2) {
    // Installation
    return project.archived == 0 && project.signed_off > 0 && project.final_signoff == 0;
  } else if (step_type === 3) {
    // Sign-Off Stage
    return project.archived == 0 && project.final_signoff > 0;
  }
  return false;
};

const dynamicSort = property => {
  return function (a, b) {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  };
};

const getProjectStep = project => {
  if (project.archived == 0 && project.final_signoff > 1) {
    return 3;
  } else if (project.archived == 0 && project.signed_off > 0) {
    return 2;
  } else if (project.archived == 0 && project.signed_off == 0) {
    return 1;
  }
  return 4;
};

const isBlob = input => {
  if ('Blob' in window && input instanceof Blob) return true;
  else return false;
};

const isBlobUrl = input => {
  if (input && input.includes('blob_url')) return true;
  return false;
};

const getImgUrl = img_file => {
  return isBlob(img_file)
    ? URL.createObjectURL(img_file)
    : isBlobUrl(img_file)
      ? img_file
      : img_file
        ? `${public_url}upload/img/${img_file}`
        : BLANK_IMG1;
};

const getMobileImgUrl = img_file => {
  return isBlob(img_file)
    ? URL.createObjectURL(img_file)
    : isBlobUrl(img_file)
      ? get_local_image_path(img_file)
      : img_file
        ? `${mobile_url}upload/img/${img_file}`
        : BLANK_IMG;
};

// const get_local_image_path = url => {
//   if (localStorage.getItem(url)) {
//     let file = localStorage.getItem(url);
//     let img_obj = base64ToFile(file);
//     let img_path = URL.createObjectURL(img_obj);
//     // console.log('get path***', url, img_path);
//     return img_path;
//   } else {
//     return '';
//   }
// };

const get_local_image_path = async url => {
  let file = await localforage.getItem(url);
  let img_obj = base64ToFile(file);
  let img_path = URL.createObjectURL(img_obj);
  return img_path;
  // await localforage.getItem(url).then(file => {

  //   let img_obj = base64ToFile(file);
  //   let img_path = URL.createObjectURL(img_obj);
  //   console.log("image path : ", img_path)
  //   return img_path;
  // }).catch(err => {
  //   console.log("localforage error: ", err)
  //   return ''
  // })
};

// const put_local_image = (id, data, type) => {
//   let url = `blob_url(${id})`;
//   localStorage.setItem(
//     url,
//     JSON.stringify({
//       type: type,
//       data: data,
//     }),
//   );
//   return url;
// };

const put_local_image = async (id, data, type) => {
  let url = `blob_url(${id})`;
  console.log('put image: ', data);
  await localforage.setItem(
    url,
    JSON.stringify({
      type: type,
      data: data,
    }),
  );
  let val = await localforage.getItem(url);
  console.log('get local image123123: ', val);
  return url;
};

const base64ToFile = data => {
  // let arr = data.split(','),
  //   mime = arr[0].match(/:(.*?);/)[1],
  //   bstr = atob(arr[1]),
  //   n = bstr.length,
  //   u8arr = new Uint8Array(n);
  let file = JSON.parse(data);
  let bstr = atob(file.data);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let file_name = new Date().getTime() + '-' + 'base.' + file.type.split('/').pop();
  let img_obj = new File([u8arr], file_name, { type: file.type });
  return img_obj;
};

const pixieToFile = data => {
  let arr = data.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let file_name = new Date().getTime() + '-' + 'base.' + mime.split('/').pop();
  let img_obj = new File([u8arr], file_name, { type: mime });
  return img_obj;
};

const getPixieImgUrl = img_file => {
  return img_file ? `upload/img/${img_file}` : BLANK_IMG;
};

const isEmptyObj = obj => {
  return Object.keys(obj).length == 0 ? true : false;
};

const getUserImgUrl = user => {
  return user && user.profile_pic
    ? `${public_url}upload/img/${user.profile_pic}`
    : `${public_url}upload/img/default-avatar.jpg`;
};

const getUserFullName = user => {
  return user ? `${user.first_name} ${user.last_name}` : '';
};

const getCompanyImgUrl = company => {
  return company && company.logo_img ? `${public_url}upload/img/${company.logo_img}` : BLANK_IMG;
};

const getProductImgUrl = product => {
  return product && product.product_img ? `${public_url}upload/img/${product.product_img}` : BLANK_IMG;
};

const IsManagerUser = user => {
  return user && user.user_type < 2;
};

const IsEngineerUser = user => {
  return user && user.user_type == 2;
};

const IsAccountAdminUser = user => {
  return user && user.user_type == 3;
};

const IsNotManagerUser = user => {
  return user && user.user_type > 1;
};

const IsEndUser = user => {
  return user && user.user_type == 6;
};

const IsNotEndUser = user => {
  return user && user.user_type != 6;
};

const getFileUrl = file => {
  return file ? `${public_url}upload/file/${file}` : null;
};

const handleOpenFile = file => {
  let file_url = getFileUrl(file);
  if (file_url) {
    const newWindow = window.open();
    newWindow.location.href = file_url;
  }
};

const removeDuplicatesFromJSON = arr => {
  return arr.filter((item, index) => arr.map(item => JSON.stringify(item)).indexOf(JSON.stringify(item)) === index);
};

const getTaskPriorityName = task => {
  return TaskPriorityRows.find(row => row.id == task.priority)
    ? TaskPriorityRows.find(row => row.id == task.priority).name
    : TaskPriorityRows.find(row => row.id == 4).name;
};

const getTaskPriorityBgName = task => {
  return TaskPriorityRows.find(row => row.id == task.priority)
    ? TaskPriorityRows.find(row => row.id == task.priority).cn
    : TaskPriorityRows.find(row => row.id == 4).cn;
};

const getTaskPriorityLabel = task => {
  return TaskPriorityRows.find(row => row.id == task.priority)
    ? TaskPriorityRows.find(row => row.id == task.priority).label
    : TaskPriorityRows.find(row => row.id == 4).label;
};

const getActivityIcon = activity => {
  // notice_type: 1-user, 2-company, 3-project, 4-task, 5-service, 7-room, 8-product, 9-calendar, 10-label, 11-product history
  const icon_rows = [
    { type: 1, icon: 'sir sir-icon-user' },
    { type: 2, icon: 'sir sir-icon-company' },
    { type: 3, icon: 'sir sir-icon-projects' },
    { type: 4, icon: 'sir sir-icon-task' },
    { type: 5, icon: 'sir sir-icon-service-desk' },
    { type: 6, icon: '' },
    { type: 7, icon: 'sir sir-icon-location' },
    { type: 8, icon: 'sir sir-icon-product' },
    { type: 9, icon: 'sir sir-icon-calendar' },
    { type: 10, icon: 'sir sir-icon-tag' },
    { type: 11, icon: 'sir sir-icon-product' },
    // ??  "sir sir-icon-workflow", "sir sir-icon-board",
  ];
  const activity_icon = icon_rows.find(row => row.type == activity.notice_type);
  return activity_icon ? activity_icon.icon : null;
};

const getActivityBg = activity => {
  const bg_rows = [
    { id: 1, bg: 'badge mediumbg' },
    { id: 2, bg: 'badge locationbg' },
    { id: 3, bg: 'badge productbg' },
    { id: 4, bg: 'badge signoffbg' },
    { id: 5, bg: 'badge taskbg' },
    { id: 6, bg: 'badge workflowbg' },
    { id: 7, bg: 'badge boardbg' },
    { id: 8, bg: 'badge projectbg' },
  ];
  if (activity.notification.includes('sign off') || activity.notification.includes('signed off')) {
    return 'badge signoffbg';
  } else if (activity.notice_type == 8) {
    return 'badge productbg';
  } else if (activity.notice_type == 4) {
    return 'badge taskbg';
  } else if (activity.notice_type == 7) {
    return 'badge locationbg';
  } else if (activity.notice_type == 3) {
    return 'badge projectbg';
  } else {
    return 'badge mediumbg';
  }
};

const stringToColor = string => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export {
  getResponsePWA,
  getResponseServer,
  isProjectStep,
  getProjectStep,
  getImgUrl,
  isBlobUrl,
  getMobileImgUrl,
  getPixieImgUrl,
  base64ToFile,
  pixieToFile,
  getUserImgUrl,
  getUserFullName,
  getCompanyImgUrl,
  getProductImgUrl,
  getFileUrl,
  IsManagerUser,
  IsNotManagerUser,
  IsAccountAdminUser,
  IsEngineerUser,
  IsEndUser,
  IsNotEndUser,
  handleOpenFile,
  removeDuplicatesFromJSON,
  getTaskPriorityName,
  getTaskPriorityBgName,
  getTaskPriorityLabel,
  getActivityIcon,
  getActivityBg,
  isEmptyObj,
  dynamicSort,
  stringToColor,
  authUser,
  put_local_image,
};
