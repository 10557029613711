import { CACHE_NAME, base_url } from 'config';
import { dataPointRows } from 'constant/Constant';

const cache_product_change_name = (product_id, _data, stage) => {
  caches.open(CACHE_NAME).then(function (cache) {
    let product_info = `${base_url}product/getProductInfo?id=${product_id}`;
    cache
      .match(product_info)
      .then(response => (response ? response.json() : null))
      .then(function (data) {
        if (data == null) return;
        let product = data.product;
        if (stage == 1) {
          product.product_name = _data.product_name;
          product.description = _data.summary;
          product.model_number = _data.model_number;
        } else {
          let Commissions = data.product.commissions;
          let commissions = _data.map(item => {
            let obj = {};
            if (Commissions.find(com => com.label == item.id)) obj = Commissions.find(com => com.label == item.id);
            else {
              obj.id = item.id + '_' + new Date().getTime();
              obj.edit = 0;
              obj.is_require = item.value ? 1 : 0;
              obj.product_id = product_id;
              let title = dataPointRows.find(pot => pot.value == item.id).label;
              obj.title = title;
            }
            obj.value = item.value;
            return obj;
          });
          data.product.commissions = [...commissions];
        }

        const jsonResponse = new Response(JSON.stringify(data), {
          headers: { 'content-type': 'application/json' },
        });
        cache.put(product_info, jsonResponse.clone()).then(function () { });
        console.log('product info', product_info, data);
      });
  });
};

// const cache_location_create = (off_id, off_room_id, project, floor, site, room_number) => {
//   let new_room = {
//     id: off_id,
//     room_id: off_room_id,
//     project_id: project.id,
//     floor_id: floor ? floor.id : null,
//     site_id: site ? site.id : null,
//     room_number: room_number,
//   };
//   caches.open(CACHE_NAME).then(function(cache) {
//     let project_info = `${base_url}project/projectInfo?project_id=${project.id}`;
//     cache
//       .match(project_info)
//       .then(function(response) {
//         return response ? response.json() : null;
//       })
//       .then(function(data) {
//         if (data == null) return;
//         data.rooms.push(new_room);
//         const jsonResponse = new Response(JSON.stringify(data), {
//           headers: { 'content-type': 'application/json' },
//         });
//         cache.put(project_info, jsonResponse.clone()).then(function() {});
//         console.log('cache puted', project_info, data);
//       });
//     let room_info = `${base_url}room/roomInfo?project_id=${project.id}&id=${off_room_id}`;
//     let room_info_empty = `${base_url}room/roomInfo?project_id=${project.id}`;
//     cache
//       .match(room_info_empty)
//       .then(response => (response ? response.json() : null))
//       .then(function(data) {
//         data.room = {
//           ...new_room,
//           img_files: [],
//           site_name: site.site_name,
//           floor_name: floor.floor_name,
//           company_id: project.company_id,
//           company_name: project.company_name,
//         };
//         data.tasks = [];
//         const jsonResponse = new Response(JSON.stringify(data), {
//           headers: { 'content-type': 'application/json' },
//         });
//         cache.put(room_info, jsonResponse.clone()).then(function() {});
//         console.log('cache puted', room_info, data);
//       });
//   });
// };

export { cache_product_change_name };
