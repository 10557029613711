import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CalendarPage from './Pages/CalendarPage';
import ProjectPage from './Pages/ProjectPage';
import CompanyPage from './Pages/CompanyPage';
import ScanPage from './Pages/ScanPage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import ProductDetail from './Pages/ProjectPage/Detail/ProductDetail';
// import { isScanPage, notScanPage } from 'redux/actions';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  // const dispatch = useDispatch();
  const location = useLocation();

  // if (location.pathname === '/scan') {
  //   dispatch(isScanPage());
  // }else{
  //   dispatch(notScanPage())
  // }

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/project'} />;
  } else if (
    authUser &&
    (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password')
  ) {
    return <Redirect to={'/project'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/project" component={ProjectPage} />
        <RestrictedRoute path="/calendar" component={CalendarPage} />
        <RestrictedRoute path="/scan" component={ScanPage} />
        <RestrictedRoute path="/company" component={CompanyPage} />
        <RestrictedRoute path="/product/:product_id" component={ProductDetail} />
        <RestrictedRoute path="/scan" component={ScanPage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
