const COMPANY_LOGO_URL = `https://logo.clearbit.com`;
const FROM_NETWORK = 1;
const FROM_CACHE = 2;
const OFFLINE_POST_MSG = 'You are offline. Your request will be sent when online.';

const ProjectStepRows = [
  { id: 0, key: 'all', label: 'All', icon: 'icon-stepper sir sir-icon-projects' },
  { id: 1, key: 'prospect', label: 'Prospect', icon: 'icon-stepper sir sir-icon-calendar', bgIcon: 'sir sir-icon-tick' },
  {
    id: 2,
    key: 'install',
    label: 'Installation',
    icon: 'icon-stepper sir sir-icon-sandclock',
    bgIcon: 'sir sir-icon-sandclock',
  },
  { id: 3, key: 'signOff', label: 'Sign Off', icon: 'icon-stepper sir sir-icon-sign-off', bgIcon: 'sir sir-icon-sign-off' },
];

const CustomerRows = [
  { id: 1, value: 'name', label: 'COMPANY NAME', align: 'left', cn: 'text-link' },
  { id: 2, value: 'site_count', label: 'SITES', align: 'right' },
  { id: 3, value: 'room_count', label: 'LOCATION', align: 'right' },
  // { id: 4, value: 'product_count', label: 'PRODUCTS', align: 'right' },
  { id: 5, value: 'project_count', label: 'PROJECTS', align: 'right' },
];

const dataPointRows = [
  { id: 1, value: 'ip_address', label: 'IP Address' },
  { id: 2, value: 'mac_address', label: 'Mac Address' },
  { id: 3, value: 'serial_number', label: 'Serial Number' },
  { id: 4, value: 'lan_port', label: 'Lan Port' },
];

const TaskPriorityRows = [
  { id: 1, label: 'Critical', cn: 'criticalbg', name: 'critical' },
  { id: 2, label: 'High', cn: 'highbg', name: 'high' },
  { id: 3, label: 'Medium', cn: 'mediumbg', name: 'medium' },
  { id: 4, label: 'Normal', cn: 'normalbg', name: 'normal' },
];

const keyDateRows = [
  { id: 1, label: 'Installation', value: 'install_date' },
  { id: 2, label: 'Sign Off', value: 'signoff_date' },
  { id: 3, label: 'Labor Warranty Expiry', value: 'labor_warranty_time' },
  { id: 4, label: 'Asset Warranty Expiry', value: null },
  { id: 5, label: 'Estimated Replacement', value: null },
  { id: 6, label: 'Last Service Date', value: null },
];

const BlockingTypeRows = [
  { id: 1, caption: 'Annual Leave', color: '#636363' },
  { id: 2, caption: 'Training', color: '#00bcd4' },
  { id: 3, caption: 'Public Holiday', color: '#26458d' },
  { id: 4, caption: 'Other', color: '#e91e63' },
];

const ColorType = [
  // { id: 1, type: "Schedule", color: "#ff9800", colorid: `5` }, //orange
  { id: 2, type: 'Task', color: '#03a9f4', colorid: `7` }, //light blue
  { id: 3, type: 'Sub Task', color: '#e91e63', colorid: `3` }, //pink
  { id: 4, type: 'Site Survey', color: '#F44336', colorid: `6` }, //red
  { id: 5, type: 'Signed off', color: '#000000', colorid: `8` }, //black
  { id: 6, type: 'Manual Entry', color: '#4CAF50', colorid: `2` }, //green
  { id: 7, type: 'Annual Leave', color: '#636363', colorid: `2` }, //light black
  { id: 8, type: 'Training', color: '#00bcd4', colorid: `2` }, //cyan
  { id: 9, type: 'Public Holiday', color: '#26458d', colorid: `2` }, //dark blue
  { id: 10, type: 'Other', color: '#e91e63', colorid: `2` }, //pink
  { id: 11, type: 'Ticket', color: '#9C27B0', colorid: `2` }, //purple
  { id: 12, type: 'Delivery', color: '#2196F3', colorid: `12` }, //purple
];

const WHITE_BOARD = `760_950_white_blank.png`;

export {
  COMPANY_LOGO_URL,
  ProjectStepRows,
  CustomerRows,
  dataPointRows,
  TaskPriorityRows,
  keyDateRows,
  FROM_NETWORK,
  FROM_CACHE,
  OFFLINE_POST_MSG,
  BlockingTypeRows,
  ColorType,
  WHITE_BOARD,
};
