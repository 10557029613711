import axios from 'axios';

const base_url = 'https://app.sirvez.com/api/';
const public_url = 'https://app.sirvez.com/';
// const base_url = 'https://bluvisual.com/api/';
// const public_url = 'https://bluvisual.com/';
const mobile_url = 'https://mobile.sirvez.com/';
const WEBSITE_URL = 'https://mobile.sirvez.com/';
const CACHE_NAME = 'SirvezAppCache';

const local_url = 'http://localhost:3000/';
const local_ip = '10.10.13.231';

const $http = axios.create({
  baseURL: base_url,
  timeout: 100000,
  // headers: {
  //   "Content-Type": "application/json; multipart/form-data"
  // },
});

$http.interceptors.request.use(request => {
  if (localStorage.getItem('token'))
    request.headers = {
      'Content-Type': 'application/json; multipart/form-data',
      'X-Auth-Token': localStorage.getItem('token'),
    };
  request.mode = 'cors';
  return request;
});

$http.interceptors.response.use(
  function(response) {
    if (response.data.status === 'TokenError') {
      //perform the manipulation here and change the response object
      localStorage.removeItem('user_id');
      alert(response.data.msg);
      return;
    }
    return response;
  },
  function(error) {
    if (!navigator.onLine) return Promise.reject('You are offline. Your request will send when online.');
    else return Promise.reject(error.message);
  },
);

export { $http, base_url, public_url, local_url, local_ip, mobile_url, WEBSITE_URL, CACHE_NAME };
