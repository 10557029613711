import React, { useEffect, Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { Grid, Divider, IconButton, Menu, MenuItem, Avatar, Checkbox, Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { $http, public_url, base_url, WEBSITE_URL } from 'config';
import { fetchError, fetchSuccess } from 'redux/actions';
import CalendarSync from './CalendarSync';
import { getResponsePWA, IsEngineerUser, IsAccountAdminUser, IsNotManagerUser, dynamicSort } from 'constant/Func';
import { LoadingBtn } from 'constant/Component';
import { BlockingTypeRows, ColorType } from 'constant/Constant';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader } from 'reactstrap';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MsalContext } from "@azure/msal-react";
import {
  IntegrationReactOutlineMultiSelect,
  IntegrationReactOutlineSelect,
  IntegrationCustomReactSelect,
} from 'constant/ReactSelect';
import EventIcon from '@material-ui/icons/Event';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(() => ({
  categoryBox: (props) => ({
    cursor: "pointer",
    fontSize: "1.1rem",
    width: "100%",
    textAlign: "center",
    paddingBottom: "0.25rem",
    paddingTop: "0.25rem",
    borderRadius: "0.4rem",
    border: `2px solid ${props.color}`,
    color: props.bool ? "white" : props.color,
    transition: "0.35s ease",
    background: props.bool ? props.color : "transparent",
    "&:hover": {
      background: props.color,
      color: "white",
    },
  }),
  categoryListBox: (props) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  }),
  categoryBadge: (props) => ({
    background: `${props.color}40`,
    border: `0.13rem solid ${props.color}80`,
    color: props.color,
    borderRadius: 5,
    fontSize: "1.1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    cursor: "pointer",
    transition: "0.25s ease",
    "&:hover": {
      opacity: 0.85,
    },
  }),
}));


const CategoryComponent = (props) => {
  const { bool, color, title, handleClick } = props;
  const classes = useStyles({ bool, color });

  return (
    <div className={classes.categoryBox} onClick={handleClick}>
      {title}
    </div>
  );
};

const CategoryBadge = ({ category, handleClick }) => {
  const color = category ? category.category_color : "#4caf50";
  const classes = useStyles({ color });
  return (
    <div className={`badge ${classes.categoryBadge}`} onClick={handleClick}>
      {category ? category.category_name : "No Label"}
    </div>
  );
};

const CategoryListComponent = (props) => {
  const { bool, color, title } = props;
  const classes = useStyles({ bool });

  return (
    <div className={classes.categoryListBox}>
      <div className="inline-middle width-150 text-truncate">
        <i
          className="zmdi zmdi-label-alt mr-1 zmdi-hc-lg"
          style={{ color: color }}
        />
        {title}
      </div>
      <i
        className={`zmdi zmdi-check text-green ml-auto zmdi-hc-lg ${bool ? "" : "visibility-hidden"
          }`}
      />
    </div>
  );
};

const CalendarPage = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [project_users, setProjectUsers] = useState([]);
  const [engineers, setEngineers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [event_categories, setEventCategories] = useState([])
  const [helpdesks, setHelpdesks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [show_detail_event, setShowDetailEvent] = useState(false)
  const [rooms, setRooms] = useState([]);
  const [sel_label, setSelLabel] = useState("")
  const [activity_loader, setActivityLoader] = useState(false)
  const [all_events, setAllEvents] = useState([]);
  const [start_datetime, setStartDatetime] = useState(new Date());
  const [end_datetime, setEndDatetime] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState([]);
  const [detail_sites, setDetailSites] = useState([]);
  const [selected_event, setSelectedEvent] = useState(null);
  const [detail_event_subject, setDetailEventSubject] = useState('');
  const [new_event_users, setNewEventUsers] = useState([]);
  const [attach_files, setAttachFiles] = useState([]);
  const [attach_filenames, setAttachFilenames] = useState([]);
  const [goback, setGoback] = useState(false);
  const [modal_loading, setModalLoading] = useState(false)
  const [btn_loading, setBtnLoading] = useState(false)
  const [calendar_project_id, setCalendarProjectId] = useState('');
  const [calendarProject, setCalendarProject] = useState(null);
  const [calendar_title, setCalendarTitle] = useState('');
  const [calendar_description, setCalendarDescription] = useState('');
  const [calendar_site_id, setCalendarSiteId] = useState('');
  const [calendar_helpdesk_id, setCalendarHelpdeskId] = useState('');
  const [calendar_room_ids, setCalendarRoomIds] = useState([]);
  const [has_attachment, setHasAttachment] = useState(false);
  const [event_users, setEventUsers] = useState([]);
  const [cur_event_type, setCurEventType] = useState(0);
  const [new_modal, setNewModal] = useState(false);
  const [is_provisional, setIsProvisional] = useState(false);
  const [is_sendEmail, setIsSendEmail] = useState(false);

  const [booking_status, setBookingStatus] = useState(1);
  const [blocking_type, setBlockingType] = useState(1);
  const [sites, setSites] = useState([]);
  const [site_rooms, setSiteRooms] = useState([]);
  const [anchorInviteEl, setAnchorInviteEl] = useState(null);
  const [show_invite_user, setShowInviteUser] = useState(false);
  const [event_user_hover, setEventUserHover] = useState(false);
  const [info_hover, setInfoHover] = useState(false);
  const [anchorCategoryListMenu, setAnchorCategoryListMenu] = useState(null)
  const [category_list_menu, setCategoryListMenu] = useState(false)

  const [detail_event, setDetailEvent] = useState({
    site_id: '',
    helpdesk_id: '',
    start_time: new Date(),
    end_time: new Date(),
    invite_users: [],
    subject: '',
    description: '',
    is_provisional: 1,
    attachment_filenames: [],
    blocking_type: 1,
  });

  const [anchorDetailInviteEl, setAnchorDetailInviteEl] = useState(null);
  const [newNotes, setNewNotes] = useState('');

  const booking_rows = [
    { id: 1, name: 'Project Booking' },
    { id: 2, name: 'Ticket' },
    { id: 3, name: 'Block Booking' },
  ];

  const booking_type = [
    { id: 1, caption: 'Provisional', color: 'red' },
    { id: 0, caption: 'Confirmed', color: 'black' },
  ];

  useEffect(() => {
    InitData();
  }, []);

  const InitData = () => {
    getResponsePWA(`${base_url}calendar/getCalendarEvents`, updateNetworkResponse, failedNetworkResponse);
  };

  const updateNetworkResponse = data => {
    let events = data.events.map(item => {
      let start_arr, end_arr = [];
      let start_time = item.start.toString();
      start_arr = start_time.split(/[- T :]/);
      let end_time = item.end.toString();
      end_arr = end_time.split(/[- T :]/);
      return {
        ...item,
        title: item.title_detail,
        start: new Date(
          start_arr[0],
          start_arr[1] - 1,
          start_arr[2],
          start_arr[3],
          start_arr[4],
          start_arr[5]
        ),
        users: item.users.filter((u) => u != ""),
        end: new Date(
          end_arr[0],
          end_arr[1] - 1,
          end_arr[2],
          end_arr[3],
          end_arr[4],
          end_arr[5]
        ),
      };
    });

    let arr = moment(new Date())
      .format('YYYY-MM-DD')
      .split(/[- T :]/);

    setUsers(data.users);
    setProjectUsers(data.project_users);
    setEngineers(data.engineers);
    setCustomers(data.customers);
    setProjects(data.projects);
    setHelpdesks(data.helpdesks);
    setRooms(data.rooms);
    setAllEvents([...events]);
    setStartDatetime(new Date(arr[0], arr[1] - 1, arr[2], '8'));
    setEndDatetime(new Date(arr[0], arr[1] - 1, arr[2], '17'));
    // setEvents(events.filter(item => item.type != 12));
    setEvents(events);
    setEventCategories(data.event_categories)
    setNewEventUsers(data.users.filter(item => item.user_type < 4));
    setLoader(true);
  };

  const failedNetworkResponse = () => {
    setGoback(true);
  };

  const handleChangeCategory = (id) => {
    setSelLabel(id)
  };

  const showEventDetail = event => {
    setModalLoading(true)

    $http
      .get(`${base_url}calendar/getEvent?event_id=${event.id}`)
      .then((response) => {
        fetchEventDetail(response.data);
      })
      .catch((error) => console.log(error));
  };

  const changeCategory = (id) => {
    setSelLabel(id)
    setDetailEvent({
      ...detail_event,
      category_type: id
    })
    setCategoryListMenu(false)
  };

  const fetchEventDetail = (data) => {
    let NewEventUsers = users.filter((item) => item.user_type < 5);
    let event = data.event;

    console.log("detail event => ", event);
    setSelectedEvent(event)
    setDetailEventSubject(event.title)
    setNewEventUsers(NewEventUsers)
    setAttachFiles([])
    setAttachFilenames(event.upload_file ?? [])
    setDetailEvent({
      ...detail_event,
      helpdesk_id: event.helpdesk_id,
      site_id: event.site_id,
      start_time: event.start,
      end_time: event.end,
      is_provisional: event.is_provisional,
      invite_users: event.users
        .filter((item) => item != "")
        .map((item) => (item = parseInt(item))),
      description: event.description ? event.description : "",
      subject: event.title,
      attachment_filenames: event.upload_file ?? [],
      blocking_type: event.booking_type - 6,
      category_type: event.category_type || "",
    })
    setActivityLoader(false)
    setModalLoading(false)
    setShowDetailEvent(true)
  };

  const detailEvent = info => {
    if (IsEngineerUser(authUser) || IsAccountAdminUser(authUser)) return;

    if (IsNotManagerUser(authUser)) {
      return;
    }

    console.log("change")

    setNewModal(true)
    if (info.start == info.end) {
      let arr = moment(info.start)
        .format('YYYY-MM-DD')
        .split(/[- T :]/);
      let event_start_date = new Date(arr[0], arr[1] - 1, arr[2], '8');
      arr = moment(info.end)
        .format('YYYY-MM-DD')
        .split(/[- T :]/);
      let event_end_date = new Date(arr[0], arr[1] - 1, arr[2], '17');
      setStartDatetime(event_start_date);
      setEndDatetime(event_end_date);
    } else {
      setStartDatetime(new Date(info.start));
      setEndDatetime(new Date(info.end));
    }

    setModalLoading(true)

    $http
      .get(
        `${base_url}calendar/getNewEvent`
      )
      .then((response) => {
        fetchNewEvent(response.data);
      })
      .catch((error) => {
        console.log(error)
        setModalLoading(false)
      });
  };

  const fetchNewEvent = (data) => {
    setProjects(data.projects)
    setHelpdesks(data.helpdesks)
    setRooms(data.rooms)
    setCalendarTitle("")
    setCalendarProjectId("")
    setCalendarHelpdeskId("")
    setCalendarDescription("")
    setCalendarSiteId("")
    setCalendarRoomIds([])
    setAttachFilenames([])
    setSelLabel("")
    setAttachFiles([])
    setHasAttachment(false)
    setEventUsers([])
    setCurEventType(0)
    setModalLoading(false)
    setNewModal(true)
    setNewEventUsers(...users)
  };

  const PickerError = type => {
    if (type == 'show_detail') return detail_event.start_time > detail_event.end_time;
    else return start_datetime > end_datetime;
  };

  const HandleSelEventUser = user_id => {
    let eventUsers = event_users;
    if (!eventUsers.includes(user_id)) eventUsers.push(user_id);
    else eventUsers = eventUsers.filter(user => user != user_id);
    setShowInviteUser(false);
    setEventUsers([...eventUsers]);
  };

  const checkAvailableEvent = user => {
    let user_events = [];
    events
      .filter(item => item.users.includes(user))
      .map(item => {
        if (!(end_datetime < item.start) && !(start_datetime > item.end)) user_events.push(item);
      });

    return {
      user_events: user_events,
      is_collied: user_events.length > 0,
    };
  };

  const CreatedBy = (created_by) => {
    let created_user = users.find(
      (item) => item.id == created_by
    );
    return (
      <div className="inline-middle row mb-2 mt-1">
        <h4 className="col-3 mb-0">Created By</h4>
        <div className="col-9 inline-middle">
          <Avatar
            alt={created_user.first_name}
            className="size-30 mr-2"
            src={`${public_url}upload/img/${created_user.profile_pic}`}
          />
          <h4 className="my-0">{created_user.first_name}</h4>
        </div>
      </div>
    );
  };

  const deleteEvent = data => {
    if (IsEngineerUser(authUser)) return;

    MySwal.fire({
      title: 'Are you really?',
      text: 'This event is removed from personal Calendars.',
      showCancelButton: true,
      focusConfirm: false,
      icon: 'warning',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      setShowDetailEvent(false);
      if (result.value) {
        const frmData = new FormData();
        frmData.append('type', selected_event.type);
        frmData.append('id', selected_event.id);
        $http
          .post(`${base_url}calendar/deleteEvent`, frmData)
          .then(response => {
            if (response.data.status === 'success') {
              InitData();
              dispatch(fetchSuccess('You have deleted that event.'));
            }
          })
          .catch(error => {
            dispatch(fetchError(error));
          });
      }
    });
  };

  const PromisedName = item => {
    let booking_type = item.type == 6 ? 1 : item.type == 11 ? 3 : 2;
    let blocking_type = item.type - 6;

    switch (booking_type) {
      case 1:
        return `${projects.find(ii => ii.id == item.project_id).customer} - ${item.project_name}`;

      case 2:
        return `${BlockingTypeRows.find(item => item.id == blocking_type).caption}`;

      case 3:
        return `${helpdesks.find(ii => ii.id == item.helpdesk_id).helpdesk} - ${helpdesks.find(ii => ii.id == item.helpdesk_id).customer
          }`;

      default:
        break;
    }
  };

  const EventUsers = event_user => {
    let user = new_event_users.find(user => user.id == event_user);
    let user_events = checkAvailableEvent(event_user);

    return (
      <div key={`event-user-${user.id}`} className="position-relative">
        <div
          className="position-relative mr-3"
          onMouseEnter={() => setEventUserHover(true)}
          onMouseLeave={() => setEventUserHover(false)}>
          <Avatar alt={user.first_name} className="size-30" src={`${public_url}upload/img/${user.profile_pic}`} />
          <div className={`invite-avatar-badge rounded ${user_events.is_collied ? 'bg-black' : 'bg-success'}`}></div>
        </div>
        {event_user_hover && user_events.is_collied && (
          <div className="position-absolute d-flex" style={{ width: 700, bottom: 36, left: -16 }}>
            <div className="bg-white" style={{ border: '1px solid red', padding: 10, borderRadius: 5 }}>
              {user_events.user_events.map(item => (
                <h4 key={item.id} className="mb-1">
                  {`${PromisedName(item)}, ${moment(item.start).format('MMM Do, h:mm a')} - ${moment(item.end).format(
                    'MMM Do, h:mm a',
                  )}`}
                </h4>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const uploadAttachmentFile = event => {
    let _length = event.target.files.length;
    let _origin_length = attach_files.length;
    attach_files.splice(_origin_length, 0, ...event.target.files);

    let _empty_names = [];
    for (var i = 0; i < _length; i++) {
      let _file = event.target.files[i];
      _empty_names.push(_file.name);
    }
    attach_filenames.splice(_origin_length, 0, ..._empty_names);

    setAttachFiles([...attach_files]);
    setAttachFilenames([...attach_filenames]);
  };
  const cancelAttachmentFile = index => {
    attach_files.splice(index, 1);
    attach_filenames.splice(index, 1);
    setAttachFiles([...attach_files]);
    setAttachFilenames([...attach_filenames]);
  };

  const EditEventModal = event => {
    let name, name_type, url, customer;
    if (event.booking_type == 6 || event.booking_type == 12) {
      name_type = 'Project';
      url = `${public_url}app/project/live/${event.project_id}`;
      if (event.project_ref && event.project_ref != "")
        name = event.project_ref + " - " + event.project_name;
      else name = event.project_name;
      customer = event.company_name;
    } else {
      name_type = 'Ticket';
      url = `${public_url}app/helpdesk/my-helpdesk/${event.helpdesk_id}`;
      name = helpdesks.find(item => item.id == event.helpdesk_id).helpdesk;
      customer = helpdesks.find(item => item.id == event.helpdesk_id).customer;
      name = event.helpdesk;
      customer = event.company_name;
    }
    return (
      <>
        <div className="row align-items-center mb-3">
          <h4 className="col-3 mb-0">{name_type}</h4>
          <div className="col-9 inline-middle">
            <a href={url}>
              <h4 className="mb-0 text-blue">{name}</h4>
            </a>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <h4 className="col-3 mb-0">Customer</h4>
          <div className="col-9">
            <h4 className="mb-0">{customer}</h4>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <h4 className="col-3 mb-0">Address</h4>
          <div className="col-9">
            <IntegrationReactOutlineSelect
              suggestions={event.sites.map(item => {
                return {
                  value: item.id,
                  label: `${item.site_name} - ${item.address}, ${item.city}, ${item.postcode}`,
                };
              })}
              isNotActive={IsNotManagerUser(authUser)}
              value={event.sites
                .filter(item => item.id == event.site_id)
                .map(item => {
                  return {
                    value: item.id,
                    label: `${item.site_name} - ${item.address}, ${item.city}, ${item.postcode}`,
                  };
                })}
              onSelect={val =>
                setDetailEvent({
                  ...detail_event,
                  site_id: val,
                })
              }
            />
          </div>
        </div>
      </>
    );
  };

  const HandleSelDetailEventUser = user_id => {
    if (
      checkAvailableEvent(user_id, selected_event).is_collied
    ) {
      dispatch(fetchError('The user is already reserved. Please select other user'))
      return;
    }
    let eventUsers = detail_event['invite_users'];
    if (eventUsers.indexOf(user_id) == -1) eventUsers.push(user_id);
    else eventUsers = eventUsers.filter(user => user != user_id);

    setShowInviteUser(false);
    setDetailEvent({
      ...detail_event,
      invite_users: [...eventUsers],
    });
  };

  const addNotes = () => {
    const frmData = new FormData();
    frmData.append('event_id', selected_event.id);
    frmData.append('type', selected_event.type);
    frmData.append('note', newNotes);
    $http
      .post(`${base_url}calendar/addNewNotes`, frmData)
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(fetchSuccess('The new note has added.'));
          setNewNotes('');
          InitData();
        }
      })
      .catch(error => {
        dispatch(fetchError(error));
      });
  };

  const SaveCalendarEntry = () => {
    if (start_datetime > end_datetime) {
      dispatch(fetchError('The start time must be before the end time.'));
      return;
    }

    if (
      event_users == [] ||
      (booking_status == 1 && (calendar_project_id == '' || calendar_site_id == '') && sel_label == "") ||
      (booking_status == 2 && (calendar_helpdesk_id == 0 || calendar_site_id == '')) ||
      calendar_title == ''
    ) {
      dispatch(fetchError('Please fill the field value'));
      return;
    }

    let is_collied = false;
    event_users.map(item => {
      if (checkAvailableEvent(item).is_collied) {
        is_collied = true;
        dispatch(fetchError('Event is colliding with someone. Please book again'));
        return;
      }
    });

    if (is_collied) return;

    setBtnLoading(true)
    const frmData = new FormData();
    frmData.append('title', calendar_title);
    frmData.append('description', calendar_description);
    frmData.append('project_id', calendar_project_id);
    frmData.append('helpdesk_id', calendar_helpdesk_id);
    frmData.append('room_ids', JSON.stringify(calendar_room_ids));
    frmData.append('start_date', moment(start_datetime).format('YYYY-MM-DD HH:mm:ss'));
    frmData.append('end_date', moment(end_datetime).format('YYYY-MM-DD HH:mm:ss'));
    frmData.append('diff_time', end_datetime.getTime() - start_datetime.getTime());
    frmData.append('event_users', JSON.stringify(event_users));
    frmData.append('is_provisional', is_provisional ? 1 : 0);
    frmData.append('address', calendar_site_id);

    for (var i = 0; i < attach_files.length; i++) {
      frmData.append('upload_file[]', attach_files[i]);
    }

    frmData.append(
      "booking_type",
      booking_status == 1
        ? 6
        : booking_status == 3
          ? 6 + blocking_type
          : 11
    );
    if (sel_label != "")
      frmData.append("category_type", sel_label);

    $http
      .post(`${base_url}calendar/saveNewCalendarEvent`, frmData)
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(fetchSuccess('You saved a new event.'));
          InitData();
          setCalendarTitle('');
          setCalendarDescription('');
          setEventUsers([]);
          setBtnLoading(false)
          setNewModal(false);
        } else if (response.data.status === 'error') {
          setBtnLoading(false)
          dispatch(fetchError(response.data.msg));
        }
      })
      .catch(error => {
        setBtnLoading(false)
        dispatch(fetchError(error));
      });
  };

  const handleOpenFile = url => {
    const newWindow = window.open();
    newWindow.location.href = `${public_url}upload/file/${url}`;
  };

  const handleSaveEvent = id => {
    setBtnLoading(true)
    const frmData = new FormData();
    frmData.append('event_id', id);
    frmData.append('detail_event', JSON.stringify(detail_event));
    frmData.append('start_time', moment(detail_event['start_time']).format('lll'));
    frmData.append('end_time', moment(detail_event['end_time']).format('lll'));
    for (var i = 0; i < attach_files.length; i++) {
      frmData.append('upload_file[]', attach_files[i]);
    }

    $http
      .post(`${base_url}calendar/changeEvent`, frmData)
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(fetchSuccess('You updated event.'));
          InitData();
          setBtnLoading(false)
          setShowDetailEvent(false)
        }
      })
      .catch(error => {
        setBtnLoading(false)
        dispatch(fetchError(error));
      });
  };

  return (
    <Suspense fallback={<PageLoader />}>
      <PageContainer>
        <GridContainer>
          <Grid item xs={12}>
            <div style={{ opacity: modal_loading ? 0.5 : 1 }}>
              <CalendarSync
                events={events}
                onSelectEvent={showEventDetail}
                onSelectSlot={detailEvent}
                color_type={ColorType}
                eventCategories={event_categories}
              />
            </div>
          </Grid>
        </GridContainer>
        {new_modal && (
          <Modal className="modal-box" toggle={() => setNewModal(false)} isOpen={new_modal}>
            <ModalHeader style={{ backgroundColor: '#05396b' }}>
              <span className="text-white">New Calendar Entry
                <IconButton
                  className="text-grey"
                  onClick={() => setNewModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </ModalHeader>
            <div className="modal-box-content">
              <div className="d-flex flex-column">
                <div className="inline-middle bg-light flex-column p-3 mb-3 rounded">
                  <div className="inline-middle">
                    <h4 className="mb-0 mr-4 calendar-new-start">Start</h4>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd MMM yyyy"
                        margin="dense"
                        value={start_datetime}
                        onChange={date => setStartDatetime(date)}
                        inputVariant="outlined"
                        className="mr-3 bg-white rounded"
                        error={PickerError()}
                      />

                      <KeyboardTimePicker
                        margin="dense"
                        autoOk
                        variant="inline"
                        error={PickerError()}
                        inputVariant="outlined"
                        value={start_datetime}
                        onChange={time => setStartDatetime(time)}
                        minutesStep={10}
                        className="bg-white rounded"
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="inline-middle">
                    <h4 className="mb-0 calendar-new-start" style={{ marginRight: 30 }}>
                      End
                    </h4>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd MMM yyyy"
                        margin="dense"
                        error={PickerError()}
                        value={end_datetime}
                        onChange={date => setEndDatetime(date)}
                        inputVariant="outlined"
                        className="mr-3 bg-white rounded"
                      />

                      <KeyboardTimePicker
                        margin="dense"
                        id="end-time-picker"
                        value={end_datetime}
                        variant="inline"
                        autoOk
                        error={PickerError()}
                        inputVariant="outlined"
                        minutesStep={10}
                        className="bg-white rounded"
                        onChange={time => setEndDatetime(time)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    {booking_rows.map(item => (
                      <div key={item.id} className="w-50">
                        <div
                          className={`bg-light rounded text-center px-4 py-2 ${booking_status == item.id ? 'text-black' : 'text-light'
                            }`}
                          style={{
                            border: `${booking_status == item.id ? '2px solid black' : '0'}`,
                            cursor: 'pointer',
                          }}
                          onClick={() => setBookingStatus(item.id)}>
                          <h4 className="mb-0">{item.name}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 d-flex-stretch mx-n3">
                    {event_categories?.map((item) => (
                      <div key={item.id} className="col-3 my-2">
                        <CategoryComponent
                          bool={item.id == sel_label}
                          color={item.category_color}
                          title={item.category_name}
                          handleClick={() => handleChangeCategory(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {booking_status != 3 && (
                  <div className="bg-light d-flex flex-column p-3 mb-3 rounded">
                    <div className="inline-middle mb-3">
                      <h4 className="mb-0 text-right mr-3">{booking_status == 2 ? 'Ticket' : 'Project'}</h4>
                      {booking_status == 2 ? (
                        <IntegrationReactOutlineSelect
                          suggestions={helpdesks.map(item => {
                            return {
                              value: item.id,
                              label: `${item.helpdesk}(${item.customer})`,
                            };
                          })}
                          value={
                            helpdesks.find(item => item.id == calendar_helpdesk_id)
                              ? helpdesks
                                .filter(item => item.id == calendar_helpdesk_id)
                                .map(item => {
                                  return {
                                    value: item.id,
                                    label: `${item.helpdesk} (${item.customer})`,
                                  };
                                })
                              : null
                          }
                          onSelect={val => {
                            let sites = helpdesks.find(item => item.id == val).sites;
                            setCalendarHelpdeskId(val);
                            setSites(sites);
                            setCalendarTitle(helpdesks.find(item => item.id == val).helpdesk);
                            setCalendarDescription(helpdesks.find(item => item.id == val).description);
                          }}
                        />
                      ) : (
                        <IntegrationReactOutlineSelect
                          suggestions={projects.map(item => {
                            return {
                              value: item.id,
                              label: `${item.project_name}(${item.po_number && item.po_number != 'null' ? item.po_number : ' - '
                                })`,
                            };
                          })}
                          value={
                            projects.find(item => item.id == calendar_project_id)
                              ? projects
                                .filter(item => item.id == calendar_project_id)
                                .map(item => {
                                  return {
                                    value: item.id,
                                    label: `${item.project_name}(${item.po_number && item.po_number != 'null' ? item.po_number : ' - '
                                      })`,
                                  };
                                })
                              : null
                          }
                          onSelect={val => {
                            let sites = projects.find(item => item.id == val).sites;
                            let _users = project_users.filter(
                              (item) => item.project_id == val
                            );
                            setCalendarProjectId(val);
                            setSites(sites);
                            setCalendarProject(projects.find(item => item.id == val));
                            setCalendarTitle(projects.find(item => item.id == val).project_name);
                            setCalendarRoomIds([])
                            setCalendarDescription(projects.find(item => item.id == val).project_summary);
                            setNewEventUsers([...new_event_users, ..._users])
                          }}
                        />
                      )}
                    </div>
                    <div className="inline-middle mb-3">
                      <h4 className="mb-0 text-right mr-3">Address</h4>
                      <IntegrationReactOutlineSelect
                        suggestions={sites.map(item => {
                          return {
                            value: item.id,
                            label: `${item.site_name} - ${item.address}, ${item.city}, ${item.postcode}`,
                          };
                        })}
                        value={
                          sites.find(item => item.id == calendar_site_id)
                            ? sites
                              .filter(item => item.id == calendar_site_id)
                              .map(item => {
                                return {
                                  value: item.id,
                                  label: `${item.site_name} - ${item.address}, ${item.city}, ${item.postcode}`,
                                };
                              })
                            : null
                        }
                        onSelect={val => {
                          setCalendarSiteId(val);
                          setSiteRooms([...rooms.filter(item => item.site_id == val)]);
                        }}
                      />
                    </div>
                    <div className="inline-middle">
                      <h4 className="mb-0 text-right mr-3">Location</h4>
                      <IntegrationReactOutlineMultiSelect
                        suggestions={site_rooms
                          .filter(item => item.site_id == calendar_site_id)
                          .map(item => {
                            return {
                              value: item.id,
                              label: item.room_number,
                            };
                          })}
                        value={site_rooms
                          .filter(item => calendar_room_ids.includes(item.id))
                          .map(item => {
                            return {
                              value: item.id,
                              label: item.room_number,
                            };
                          })}
                        onSelect={val => setCalendarRoomIds(val)}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3 bg-light rounded px-5 py-3 inline-middle">
                  <h4 className="mb-0 mr-3">Invite</h4>
                  <div className="inline-middle">
                    <IconButton
                      className="mr-2"
                      onClick={event => {
                        setAnchorInviteEl(event.currentTarget);
                        setShowInviteUser(true);
                      }}>
                      <i className="sir sir-icon-plus font-3" />
                    </IconButton>
                    <Menu
                      anchorEl={anchorInviteEl}
                      open={show_invite_user}
                      onClose={() => setShowInviteUser(false)}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                      {new_event_users.sort(dynamicSort('first_name')).map(item => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          onClick={() => HandleSelEventUser(item.id)}
                          className="d-flex justify-content-between">
                          <div className="inline-middle">
                            <div className="position-relative">
                              <Avatar
                                alt={item.first_name}
                                className="size-30 mr-2"
                                src={`${public_url}upload/img/${item.profile_pic}`}
                              />
                              <div
                                className={`invite-avatar-badge rounded ${checkAvailableEvent(item.id).is_collied ? 'bg-black' : 'bg-success'
                                  }`}></div>
                            </div>
                            {`${item.first_name} ${item.last_name}`}
                            {item.user_type == 4 ? (
                              <div className="badge mb-0 ml-2 py-0 rounded-pill text-white bg-orange">
                                Contractor
                              </div>
                            ) : item.user_type == 5 ? (
                              <div className="badge mb-0 ml-2 py-0 rounded-pill text-white bg-green">
                                Admin User
                              </div>
                            ) : null}
                          </div>
                          {event_users.find(user => user == item.id) && (
                            <i className="zmdi zmdi-check zmdi-hc-lg text-green" />
                          )}
                        </MenuItem>
                      ))}
                    </Menu>
                    {event_users.map(item => (
                      <div key={item}>{EventUsers(item)}</div>
                    ))}
                  </div>
                </div>
                <div className="mb-3 bg-light rounded pl-3 pr-4 py-3">
                  <div className="inline-middle mb-2">
                    <h4 style={{ width: 120 }} className="mr-3 mb-0 text-right">
                      Subject
                    </h4>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className="bg-white"
                      value={calendar_title}
                      onChange={event => setCalendarTitle(event.target.value)}
                    />
                  </div>
                  <div className="inline-middle">
                    <h4 style={{ width: 120 }} className="mr-3 mb-0 text-right">
                      Description
                    </h4>
                    <TextField
                      fullWidth
                      multiline
                      margin="dense"
                      variant="outlined"
                      minRows={4}
                      value={calendar_description ? calendar_description : ''}
                      onChange={event => setCalendarDescription(event.target.value)}
                      className="bg-white"
                    />
                  </div>
                </div>
                <div>
                  <div className="inline-middle">
                    <Checkbox
                      id="is_provisional"
                      color="primary"
                      checked={is_provisional}
                      className="py-2"
                      onChange={event => {
                        setIsProvisional(event.target.checked);
                        if (event.target.checked) setCalendarTitle('P-' + calendar_title);
                        else setCalendarTitle(calendar_title.substr(2, calendar_title.length));
                      }}
                    />
                    <span className="inline-middle">
                      Provisional
                      <div className="position-relative">
                        <InfoOutlinedIcon
                          onMouseEnter={() => setInfoHover(true)}
                          onMouseLeave={() => setInfoHover(false)}
                          className="ml-1"
                          style={{ width: 20, transform: 'rotate(12deg)' }}
                        />
                        {info_hover && (
                          <div className="position-absolute" style={{ bottom: 8, left: 30 }}>
                            <p
                              className="p-2 bg-white"
                              style={{
                                border: '1px solid red',
                                borderRadius: 5,
                                width: 250,
                                transition: 'all 0.5s ease',
                              }}>
                              Create a provisional booking to save a date and engineering resource from being used up (until
                              you have the booking confirmed by the customer for example).
                            </p>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>

                  <div className="inline-middle">
                    <Checkbox
                      id="is_provisional"
                      color="primary"
                      checked={has_attachment}
                      onChange={event => setHasAttachment(event.target.checked)}
                      className="py-2"
                    />

                    <span>Include Attachment</span>

                    {has_attachment && (
                      <>
                        <input
                          type="file"
                          id="upload_file"
                          accept=""
                          multiple
                          name="upload_file"
                          style={{ display: 'none' }}
                          onChange={event => {
                            uploadAttachmentFile(event);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          className="btn btn-primary p-3 jr-btn ml-4"
                          onClick={() => {
                            document.getElementById('upload_file').click();
                          }}
                          endIcon={<i className="zmdi zmdi-cloud-upload zmdi-hc-2x ml-2" />}>
                          Upload File
                        </Button>
                      </>
                    )}
                  </div>
                  <div>
                    {attach_filenames.map((file, index) => (
                      <div key={index} className="inline-middle px-2">
                        <div>{file}</div>
                        <IconButton className="text-red py-0 px-2" onClick={() => cancelAttachmentFile(index)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-right mt-2">
                  <LoadingBtn back_color={'purple'} loading={btn_loading} handleClick={SaveCalendarEntry} />
                </div>
              </div>
            </div>
          </Modal>
        )}

        {show_detail_event && (
          <Modal className="modal-box comment_modal" toggle={() => setShowDetailEvent(false)} isOpen={show_detail_event}>
            <ModalHeader
              className="text-white py-2"
              style={{
                backgroundColor: `${detail_event.category_type &&
                  detail_event.category_type != ""
                  ? event_categories.find(
                    (item) =>
                      item.id == detail_event.category_type
                  )?.category_color
                  : selected_event.booking_type
                    ? selected_event.booking_type == 12
                      ? "#2196f3"
                      : ColorType.find(
                        (item) =>
                          item.id == selected_event.booking_type
                      )?.color
                    : ""
                  }`,
              }}
            >
              <div className="inline-middle justify-content-between">
                <div className="d-flex justify-content-start align-items-center text-white">
                  <EventIcon className="size-30 mr-3" />
                  {selected_event.type == 6 ? (selected_event.is_provisional == 1 ? 'Provisional - ' : 'Confirmed - ') : ''}
                  {selected_event.type == 12 ? selected_event.title_only : selected_event.title}
                  {selected_event.type != 12 &&
                    selected_event.room_id &&
                    '-' + selected_event.room_id &&
                    rooms
                      .filter(row => selected_event.room_id.split(',').indexOf(row.id + '') != -1)
                      .map(row => row.room_number)
                      .toString()}
                </div>
                <IconButton
                  className="text-white"
                  onClick={() => setShowDetailEvent(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </ModalHeader>

            {/* new modal */}
            <div className="modal-box-content">
              <div>
                <div className="mb-3">
                  <div id="detail-modal-left" className="">
                    <div className="d-flex flex-column">
                      <div className="bg-light flex-column p-4 mb-3 rounded">
                        {selected_event.booking_type == 6 || selected_event.booking_type == 11 || selected_event.booking_type == 12 ? (
                          <>{EditEventModal(selected_event)}</>
                        ) : (
                          <div className="d-flex mb-3" style={{ marginLeft: -30, marginRight: -30 }}>
                            {BlockingTypeRows.map((item, index) => (
                              <div
                                className={`border-2 flex-fill rounded inline-middle justify-content-center ${index == 3 ? 'mr-0' : 'mr-2'
                                  } p-2 ${detail_event['blocking_type'] == item.id ? ' border-green' : ''}`}
                                key={index}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (IsNotManagerUser(authUser)) return;

                                  setDetailEventSubject(item.caption);
                                  setDetailEvent({
                                    ...detail_event,
                                    blocking_type: item.id,
                                    subject: item.caption,
                                  });
                                }}>
                                <BrandingWatermarkIcon className="mr-2" style={{ color: `${item.color}` }} />
                                <h4 className="mb-0">{item.caption}</h4>
                              </div>
                            ))}
                          </div>
                        )}

                        {CreatedBy(selected_event.created_by)}

                        {selected_event.booking_type == 12 ? (
                          <div className="inline-middle row">
                            <div className="col-3">
                              <h4 className="mb-0 text-truncate">Collection Date</h4>
                            </div>
                            <div className="col-9 d-flex">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  autoOk
                                  variant="inline"
                                  format="dd MMM yyyy"
                                  margin="dense"
                                  id="start-inline-datapicker"
                                  value={detail_event['start_time']}
                                  disabled={IsNotManagerUser(authUser)}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  inputVariant="outlined"
                                  className="mr-3 bg-white rounded"
                                />

                                <KeyboardTimePicker
                                  margin="dense"
                                  autoOk
                                  variant="inline"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  inputVariant="outlined"
                                  value={detail_event['start_time']}
                                  disabled={IsNotManagerUser(authUser)}
                                  minutesStep={10}
                                  className="bg-white rounded"
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="inline-middle row">
                              <div className="col-3">
                                <h4 className="mb-0 calendar-start">Start</h4>
                              </div>
                              <div className="col-9 d-flex">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="dd MMM yyyy"
                                    margin="dense"
                                    value={detail_event['start_time']}
                                    disabled={IsNotManagerUser(authUser)}
                                    onChange={date =>
                                      setDetailEvent({
                                        ...detail_event,
                                        start_time: date,
                                      })
                                    }
                                    inputVariant="outlined"
                                    className="mr-3 bg-white rounded"
                                    error={PickerError('show_detail')}
                                  />

                                  <KeyboardTimePicker
                                    margin="dense"
                                    autoOk
                                    variant="inline"
                                    error={PickerError('show_detail')}
                                    inputVariant="outlined"
                                    value={detail_event['start_time']}
                                    disabled={IsNotManagerUser(authUser)}
                                    onChange={time => {
                                      setDetailEvent({
                                        ...detail_event,
                                        start_time: time,
                                      });
                                    }}
                                    minutesStep={10}
                                    className="bg-white rounded"
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>

                            <div className="inline-middle row">
                              <div className="col-3">
                                <h4 className="mb-0 calendar-end">End</h4>
                              </div>

                              <div className="col-9 d-flex">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    autoOk
                                    variant="inline"
                                    format="dd MMM yyyy"
                                    margin="dense"
                                    error={PickerError('show_detail')}
                                    value={detail_event['end_time']}
                                    disabled={IsNotManagerUser(authUser)}
                                    onChange={date => {
                                      setDetailEvent({
                                        ...detail_event,
                                        end_time: date,
                                      });
                                    }}
                                    inputVariant="outlined"
                                    className="mr-3 bg-white rounded"
                                  />

                                  <KeyboardTimePicker
                                    margin="dense"
                                    id="end-time-picker"
                                    value={detail_event['end_time']}
                                    variant="inline"
                                    autoOk
                                    error={PickerError('show_detail')}
                                    disabled={IsNotManagerUser(authUser)}
                                    inputVariant="outlined"
                                    minutesStep={10}
                                    className="bg-white rounded"
                                    onChange={time => {
                                      setDetailEvent({
                                        ...detail_event,
                                        end_time: time,
                                      });
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mb-3 bg-light rounded px-4 py-3 inline-middle">
                        <h4 className="mb-0 mr-3">Invite</h4>
                        <div className="inline-middle">
                          <IconButton
                            className="mr-2"
                            disabled={IsNotManagerUser(authUser)}
                            onClick={e => {
                              setAnchorDetailInviteEl(e.currentTarget);
                              setShowInviteUser(true);
                            }}>
                            <i className="sir sir-icon-plus font-3" />
                          </IconButton>
                          <Menu
                            anchorEl={anchorDetailInviteEl}
                            open={show_invite_user}
                            onClose={() => setShowInviteUser(false)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}>
                            {new_event_users.sort(dynamicSort('first_name')).map(item => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                onClick={() => HandleSelDetailEventUser(item.id)}
                                className="d-flex justify-content-between">
                                <div className="inline-middle">
                                  <div className="position-relative">
                                    <Avatar
                                      alt={item.first_name}
                                      className="size-30 mr-2"
                                      src={`${public_url}upload/img/${item.profile_pic}`}
                                    />
                                    <div
                                      className={`invite-avatar-badge rounded ${checkAvailableEvent(item.id).is_collied ? 'bg-black' : 'bg-success'
                                        }`}
                                    />
                                  </div>
                                  {`${item.first_name} ${item.last_name}`}
                                </div>
                                {detail_event['invite_users'].find(user => user == item.id) && (
                                  <i className="siz siz-icon-check text-green" />
                                )}
                              </MenuItem>
                            ))}
                          </Menu>
                          {detail_event['invite_users'].length > 0 &&
                            detail_event['invite_users']
                              .filter(item => new_event_users.find(user => user.id == item))
                              .map((item, index) => {
                                let user = new_event_users.find(user => user.id == item);
                                return (
                                  <div key={index} className="position-relative mr-3">
                                    <Avatar
                                      alt={user.first_name}
                                      className="size-30"
                                      src={`${public_url}upload/img/${user.profile_pic}`}
                                    />
                                    <div
                                      className={`invite-avatar-badge rounded ${checkAvailableEvent(item).is_collied ? 'bg-black' : 'bg-success'
                                        }`}></div>
                                  </div>
                                );
                              })}
                        </div>
                      </div>

                      <div className="mb-3 bg-light rounded px-4 py-3 inline-middle">
                        <h4 className="mb-0 mr-3">Attachments</h4>
                        <div className="inline-middle">
                          <input
                            type="file"
                            id="attachment_file"
                            accept=""
                            multiple
                            name="attachment_file"
                            style={{ display: 'none' }}
                            onChange={event => {
                              uploadAttachmentFile(event);
                            }}
                          />
                          <IconButton
                            className="mr-2"
                            disabled={IsNotManagerUser(authUser)}
                            onClick={e => {
                              document.getElementById('attachment_file').click();
                            }}>
                            <i className="sir sir-icon-plus font-3" />
                          </IconButton>
                        </div>
                        <div className="d-flex-stretch">
                          {attach_filenames &&
                            attach_filenames.map((item, index) => (
                              <div key={index} className="inline-middle px-2">
                                <div className="calendar-attachment-file" onClick={() => handleOpenFile(item)}>
                                  {item}
                                </div>
                                <IconButton
                                  className="text-red py-0 px-2"
                                  disabled={IsNotManagerUser(authUser)}
                                  onClick={() => cancelAttachmentFile(index)}>
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="bg-light rounded pl-3 pr-4 py-3">
                        <div className="inline-middle mb-2">
                          <h4 style={{ width: 120 }} className="mr-3 mb-0 text-right">
                            Subject
                          </h4>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className="bg-white"
                            disabled={IsNotManagerUser(authUser)}
                            value={detail_event_subject}
                            onChange={e => {
                              setDetailEventSubject(e.target.value);
                              setDetailEvent({
                                ...detail_event,
                                subject: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="inline-middle">
                          <h4 style={{ width: 120 }} className="mr-3 mb-0 text-right">
                            Description
                          </h4>
                          <TextField
                            fullWidth
                            multiline
                            margin="dense"
                            variant="outlined"
                            minRows={4}
                            value={detail_event['description']}
                            disabled={IsNotManagerUser(authUser)}
                            onChange={e =>
                              setDetailEvent({
                                ...detail_event,
                                description: e.target.value,
                              })
                            }
                            className="bg-white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="col-12 d-flex flex-column">
                    {detail_event.category_type &&
                      detail_event.category_type != "" ? (
                      <div>
                        <CategoryBadge
                          category={event_categories.find(
                            (item) =>
                              item.id == detail_event.category_type
                          )}
                          handleClick={(ev) => {
                            if (authUser.user_type > 3) return
                            setAnchorCategoryListMenu(ev.currentTarget)
                            setCategoryListMenu(true)
                          }
                          }
                        />
                        <Menu
                          anchorEl={anchorCategoryListMenu}
                          open={category_list_menu}
                          onClose={() =>
                            setCategoryListMenu(false)
                          }
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          {event_categories
                            .map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                onClick={() => changeCategory(item.id)}
                              >
                                <CategoryListComponent
                                  bool={
                                    !detail_event.category_type
                                      ? false
                                      : item.id ==
                                      detail_event.category_type
                                  }
                                  color={item.category_color}
                                  title={item.category_name}
                                />
                              </MenuItem>
                            ))}
                        </Menu>
                      </div>
                    ) : null}
                  </div>

                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-7 d-flex">
                    {selected_event.type != 12 &&
                      booking_type.map(item => (
                        <div
                          key={item.id}
                          className={`flex-fill inline-middle rounded justify-content-center py-3 ${item.id == 1 ? 'mr-3' : 'mr-0'
                            } ${detail_event['is_provisional'] == item.id ? `bg-${item.color}` : `bg-white`}`}
                          style={{
                            cursor: 'pointer',
                            border: `1px solid ${item.color}`,
                          }}
                          onClick={() => {
                            if (item.id == 1) {
                              setDetailEvent({
                                ...detail_event,
                                subject: 'P-' + detail_event['subject'],
                                is_provisional: item.id,
                              });
                              setDetailEventSubject('P-' + detail_event['subject']);
                            } else {
                              let subject = detail_event_subject.substr(2, detail_event_subject.length);
                              setDetailEvent({
                                ...detail_event,
                                subject: subject,
                                is_provisional: item.id,
                              });
                              setDetailEventSubject(subject);
                            }
                          }}>
                          <h4
                            className={`mb-0 ${detail_event['is_provisional'] == item.id ? 'text-white' : `text-${item.color}`
                              }`}>
                            {item.caption}
                          </h4>
                        </div>
                      ))}
                  </div>
                  <div className="col-12 col-md-5 d-flex text-right mt-3">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteEvent(selected_event)}
                      className="mr-2 text-capitalize px-5 py-2"
                      disabled={IsNotManagerUser(authUser)}>
                      Delete
                    </Button>
                    <LoadingBtn style={{
                      backgroundColor: `${detail_event.category_type &&
                        detail_event.category_type != ""
                        ? event_categories.find(
                          (item) =>
                            item.id == detail_event.category_type
                        )?.category_color
                        : selected_event.booking_type
                          ? selected_event.booking_type == 12
                            ? "#2196f3"
                            : ColorType.find(
                              (item) =>
                                item.id == selected_event.booking_type
                            )?.color
                          : ""
                        }`
                    }} back_color={'purple'} loading={btn_loading} handleClick={() => handleSaveEvent(selected_event.id)} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {/* <SweetAlert
          show={show_sync_delete}
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Warning!"
          onConfirm={() => this.deleteEventSyncYes()}
          onCancel={() => this.deleteEventSyncNo()}
        >
          Remove Calendar event from personal Calendars?
        </SweetAlert> */}
      </PageContainer>
    </Suspense>
  );
};

export default CalendarPage;
