import React, { useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const localizer = momentLocalizer(moment);

const CalendarSync = props => {
  const { events, color_type, onSelectEvent, onSelectSlot, eventCategories } = props;

  useEffect(() => {
    update();
  }, [events]);

  const History = useHistory();

  const update = () => {
    let idx = 0;
    const updateInterval = setInterval(function () {
      idx++;
      //if (idx>100)clearInterval(updateInterval);
      let pls_length = document.querySelectorAll('.rbc-event-content').length;
      if (pls_length) {
        let components = document.querySelectorAll('.rbc-event-content');
        for (var i = 0; i < pls_length; i++) {
          let sel_component = components[i];
          let parent_node = sel_component.parentElement;
          if (parent_node.style.color == 'red') {
            let paras = parent_node.querySelectorAll('.text-right');
            if (paras.length > 0) {
              paras[0].remove();
            }
            parent_node.style.color = 'white';
            let red_mark = document.createElement('div');
            red_mark.className = 'w-100 text-right';
            let red_icon = document.createElement('div');
            red_icon.className = 'size-10 bg-danger rounded-circle';
            red_mark.appendChild(red_icon);
            parent_node.appendChild(red_mark);
            let borders = parent_node.style.border;
            let comments = parseInt(borders.substring(0, borders.length - 2));
            if (comments > 0) {
              let badge = document.createElement('div');
              badge.className = 'bg-amber rounded-circle badge1 text-white m-0 mr-1';
              badge.style.position = 'absolute';
              badge.style.top = '0px';
              badge.style.right = '-5px';
              badge.style.boxShadow = 'rgba(0,0,0,0.56) 1px 1px 12px 9px';
              badge.innerText = comments;
              parent_node.appendChild(badge);
            }
          } else if (parent_node.style.color == 'black') {
            let paras = parent_node.querySelectorAll('.text-right');
            if (paras.length > 0) {
              paras[0].remove();
            }
            parent_node.style.color = 'white';
            let black_mark = document.createElement('div');
            black_mark.className = 'w-100 text-right';
            let black_icon = document.createElement('div');
            black_icon.className = 'size-10 bg-black rounded-circle';
            black_mark.appendChild(black_icon);
            parent_node.appendChild(black_mark);
            let borders = parent_node.style.border;
            let comments = parseInt(borders.substring(0, borders.length - 2));
            if (comments > 0) {
              let badge = document.createElement('div');
              parent_node.style.position = 'relative';
              badge.className = 'bg-amber rounded-circle badge1 text-white m-0 mr-1';
              badge.style.position = 'absolute';
              badge.style.top = '0px';
              badge.style.right = '-5px';
              badge.style.boxShadow = 'rgba(0,0,0,0.56) 1px 1px 12px 9px';
              badge.innerText = comments;
              parent_node.appendChild(badge);
            }
          }
        }
      }
    }, 100);
  };
  function EventAgenda({ event }) {
    return (
      <span
        onClick={() => {
          if (event.task_id > 0)
            History.push({
              pathname: `/app/task-manager/my-tasks/${event.task_id}`,
            });
        }}
        style={{ cursor: 'pointer' }}>
        {/* <em style={{ color: 'magenta'}}>{event.title}</em> */}
        <p>{event.title}</p>
      </span>
    );
  }

  return (
    <Calendar
      localizer={localizer}
      selectable
      events={events}
      defaultView="week"
      min={new Date(0, 0, 0, 6, 0, 0)}
      max={new Date(0, 0, 0, 19, 0, 0)}
      scrollToTime={new Date(1970, 1, 1, 6)}
      defaultDate={new Date()}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      eventPropGetter={(event, start, end, isSelected) => {
        let newStyle = {
          backgroundColor: `${event.category_type && event.category_type != ""
            ? eventCategories.find((item) => item.id == event.category_type)
              ?.category_color
            : color_type.find((item) => item.id == event.booking_type)?.color
            }`,
          color: `${event.is_provisional == 1 ? 'red' : event.is_provisional == 0 ? 'black' : 'white'}`,
          borderRadius: '0px',
          border: `${event.notes_number + 'px'}`,
        };
        return {
          style: newStyle,
        };
      }}
      components={{
        agenda: {
          event: EventAgenda,
        },
      }}
    />
  );
};

export default CalendarSync;
