import { NOT_SCAN_PAGE, IS_SCAN_PAGE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  is_scan: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_SCAN_PAGE: {
      return { ...state, is_scan: true };
    }
    case NOT_SCAN_PAGE: {
      return { ...state, is_scan: false };
    }
    default:
      return state;
  }
};
