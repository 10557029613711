import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  CircularProgress,
  Box,
  Slide,
  Tooltip,
  Backdrop,
  Menu,
  MenuItem,
  Button,
  Switch,
  Avatar,
  InputBase,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ProjectStepRows } from 'constant/Constant';
import { CACHE_NAME, public_url, base_url, local_ip, $http } from 'config';
import Moment from 'moment';
import { Pagination } from '@material-ui/lab';
import { stringToColor, getProjectStep, getTaskPriorityBgName, getTaskPriorityLabel } from './Func';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { cache_project_selected, is_existing_check, cache_file_array } from 'constant/CacheManage/CacheManage.js';

const customStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: 'calc(100% - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  more_button: {
    background: '#45bfa9',
    color: '#05396b',
    borderRadius: 80,
    marginRight: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loadingBtn: props => ({
    transition:
      'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important',
    backgroundColor: props.bg_color,
    color: 'white',
    '&:hover': {
      background: props.bg_color,
      opacity: 0.8,
    },
  }),
}));

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: '#2a7062',
  },
})(Tooltip);

const FilterComponent = props => {
  const { FilterMenus, handleFilterClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = action => {
    handleFilterClose();
    handleFilterClick(action);
  };

  return (
    <TableCell>
      <div className="m-1 fab43-default" onClick={event => handleFilterOpen(event)}>
        <BlueOnGreenTooltip title={`Filter Projects`} placement="top">
          <i className="sir sir-icon-filter icon-small"></i>
        </BlueOnGreenTooltip>
      </div>
      <Menu className="card-body" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleFilterClose}>
        {FilterMenus.map(action => (
          <MenuItem key={action.id} className="card-body-item" onClick={() => handleActionClick(action)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </TableCell>
  );
};

const TableHeadContainer = props => {
  const { TopMenus, FilterMenus, handleFilterClick, frtDisabled } = props;
  return (
    <TableHead>
      <TableRow>
        {frtDisabled ? null : <TableCell></TableCell>}
        {TopMenus.map(menu => (
          <TableCell key={menu.id}>{menu.label}</TableCell>
        ))}
        {FilterMenus ? <FilterComponent FilterMenus={FilterMenus} handleFilterClick={handleFilterClick} /> : null}
      </TableRow>
    </TableHead>
  );
};

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(6),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '14px 26px 14px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const GreenSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#389583',
    },
    '&$checked + $track': {
      backgroundColor: '#31c772',
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledAvatar = props => {
  const { imgUrl, alt, strColor, className, variant } = props;
  return (
    <Avatar
      src={`${public_url}upload/img/${imgUrl}`}
      className={clsx('rounded-circle', className)}
      children={alt}
      style={{
        background: imgUrl ? 'transparent' : stringToColor(strColor),
      }}
      variant={variant || 'rounded'}></Avatar>
  );
};

const TableCellContainer = props => {
  const {
    ContentRow,
    TopMenus,
    handleClick,
    FrtContent,
    LastContent,
    customClass,
    ActionMenus,
    ActionClick,
    TableType,
    RefreshCache,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [project_done, setProjectDone] = useState(0);
  const [cache_project, setCacheProject] = useState(0);
  const [cached_project, setCachedProject] = useState(0);

  useEffect(() => {
    if (TableType === 'Project' && ContentRow['project_name']) {
      let projectInfo = `${base_url}project/projectInfo?project_id=${ContentRow.id}`;
      if (window.location.hostname === local_ip) return;
      caches.open(CACHE_NAME).then(function (cache) {
        cache
          .match(projectInfo)
          .then(response => (response ? response.json() : null))
          .then(function (data) {
            if (data == null) return;
            console.log('cached data', data);
            setCachedProject(1);
          });
      });
    }
  }, [RefreshCache]);

  const handleRowClick = () => {
    if (typeof handleClick === 'function') {
      handleClick(ContentRow);
    }
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = action => {
    handleMenuClose();
    ActionClick(action, ContentRow);
  };

  const handleDownloadProject = (event, project) => {
    event.stopPropagation();
    setCacheProject(1);
    console.log('download project', project);

    let jsFiles = `${base_url}offline/getMobileJavascripts`;

    cache_project_selected(project).then(() => {
      if (!is_existing_check(jsFiles)) {
        $http.get(`${base_url}offline/getMobileJavascripts`).then(response => {
          cache_file_array(response.data.files).then(() => {
            setProjectDone(1);
            cache_file_array(response.data.pixie);
          });
        });
      } else setProjectDone(1);
    });
  };

  return (
    <TableRow style={{ cursor: 'pointer' }} onClick={() => handleRowClick()} className={customClass ?? ''}>
      {FrtContent ? <FrtContent /> : null}
      {TopMenus.map(menu => {
        switch (menu.value) {
          case 'stage': // project - stage
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                <div className="mr-2 table-status-project yes">
                  <ul>
                    {ProjectStepRows.filter(item => item.id > 0).map(step => (
                      <li key={step.id}>
                        <span
                          className={`bs-stepper-circle ${getProjectStep(ContentRow) === step.id
                            ? `current`
                            : getProjectStep(ContentRow) > step.id
                              ? `completed`
                              : ``
                            }`}>
                          {getProjectStep(ContentRow) >= step.id ? <span className={step.bgIcon}></span> : null}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </TableCell>
            );
          // case 'offline_download': // Download button
          //   return cached_project === 1 ? (
          //     <TableCell key={menu.id} className={menu.cn || ''}>
          //       <div className="inline-middle">
          //         <Button variant="contained" color="primary" disabled startIcon={<i className="zmdi zmdi-check" />}>
          //           Cached
          //         </Button>
          //         <i className={`refresh-icon-btn zmdi zmdi-refresh zmdi-hc-lg text-purple ml-1 font-weight-bold`} onClick={(event) => handleDownloadProject(event, ContentRow)} />
          //       </div>
          //     </TableCell>
          //   ) : project_done === 1 ? (
          //     <TableCell key={menu.id} className={menu.cn || ''}>
          //       <div className="inline-middle">
          //         <Button variant="contained" color="primary" disabled startIcon={<i className="zmdi zmdi-check" />}>
          //           Stored
          //         </Button>
          //         <i className={`refresh-icon-btn zmdi zmdi-refresh zmdi-hc-lg text-purple ml-1 font-weight-bold ${cache_project ? 'zmdi-hc-spin' : ''}`} onClick={(event) => handleDownloadProject(event, ContentRow)} />
          //       </div>
          //     </TableCell>
          //   ) : (
          //     <TableCell key={menu.id} className={menu.cn ?? ''}>
          //       <Button
          //         variant="contained"
          //         size="small"
          //         className="text-white text-capitalize bg-purple"
          //         color="primary"
          //         startIcon={<i className={`zmdi zmdi-refresh zmdi-hc-lg ${cache_project ? 'zmdi-hc-spin' : ''}`} />}
          //         onClick={event => handleDownloadProject(event, ContentRow)}
          //         disabled={cache_project === 1}>
          //         Download
          //       </Button>
          //     </TableCell>
          //   );
          case 'offline_download': // Download button
            return project_done === 1 ? (
              <TableCell key={menu.id} className={menu.cn || ''}>
                <div className="inline-middle">
                  <Button variant="contained" color="primary" disabled startIcon={<i className="zmdi zmdi-check" />}>
                    Stored
                  </Button>
                  <i
                    className={`refresh-icon-btn zmdi zmdi-refresh zmdi-hc-lg text-purple ml-1 font-weight-bold ${cache_project ? 'zmdi-hc-spin' : ''
                      }`}
                    onClick={event => handleDownloadProject(event, ContentRow)}
                  />
                </div>
              </TableCell>
            ) : (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                <Button
                  variant="contained"
                  size="small"
                  className="text-white text-capitalize bg-purple"
                  color="primary"
                  startIcon={<i className={`zmdi zmdi-refresh zmdi-hc-lg ${cache_project ? 'zmdi-hc-spin' : ''}`} />}
                  onClick={event => handleDownloadProject(event, ContentRow)}
                  disabled={cache_project === 1}>
                  Download
                </Button>
              </TableCell>
            );
          case 'in_stock': // product - in stock
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''} align="center">
                <div className={`mx-auto table-status ${ContentRow[menu.value] === 1 ? 'yes' : 'no'}`}>
                  {ContentRow[menu.value] == 1 ? <i className="sir sir-icon-tick" /> : '-'}
                </div>
              </TableCell>
            );
          case 'collection_date': // delivery - COLLECTION DATE
          case 'due_by_date': // location - task - due by date
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                {Moment(ContentRow[menu.value]).format('Do MMM YYYY')}
              </TableCell>
            );
          case 'project_rooms_count':
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                {ContentRow[menu.value]}
              </TableCell>
            );
          case 'remain_days': // delivery - DAYS
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                {ContentRow.is_confirm == 2 ? (
                  <div className="text-white badge bg-primary">Completed</div>
                ) : ContentRow.remain_days == 0 ? (
                  'Today'
                ) : ContentRow.remain_days < 0 ? (
                  `${ContentRow.remain_days} Days Left`
                ) : (
                  `${ContentRow.remain_days} Days Left`
                )}
              </TableCell>
            );
          case 'task': // location - task name
            return (
              <BlueOnGreenTooltip key={menu.id} title={ContentRow[menu.value]} placement="top">
                <TableCell className={menu.cn ?? ''}>
                  <div className="d-flex d-column">
                    <span className="product-title">{ContentRow[menu.value]}</span>
                    {ContentRow['archived'] != 2 ? (
                      <div className="d-flex">
                        <span className={`badge mr-2 ${getTaskPriorityBgName(ContentRow)}`}>
                          {getTaskPriorityLabel(ContentRow)} Priority
                        </span>
                      </div>
                    ) : null}
                  </div>
                </TableCell>
              </BlueOnGreenTooltip>
            );
          case 'workflow_name': // location - task - workflow name
            return (
              <TableCell key={menu.id} className={menu.cn ?? ''}>
                {ContentRow[menu.value] ? (
                  <div className="p-2 mr-2 workflow workflowbg d-flex align-items-start">
                    <i className="mt-1 mr-2 sir sir-icon-workflow icon-large"></i>
                    <a>{ContentRow[menu.value]}</a>
                  </div>
                ) : null}
              </TableCell>
            );
          case 'created_at': // location - create
            return <TableCell key={menu.id}>{Moment(ContentRow[menu.value]).format('DD/MM/YY')}</TableCell>;
          case 'img_files': // location - images
            return <TableCell key={menu.id}>{ContentRow[menu.value] ? ContentRow[menu.value].length : 0}</TableCell>;
          default:
            return menu.tooltip ? (
              <BlueOnGreenTooltip key={menu.id} title={ContentRow[menu.value]} placement="top">
                <TableCell className={menu.cn ?? ''} align="center">
                  {ContentRow[menu.value]}
                </TableCell>
              </BlueOnGreenTooltip>
            ) : (
              <TableCell key={menu.id} className={menu.cn ?? ''} align="center">
                {ContentRow[menu.value]}
              </TableCell>
            );
        }
      })}

      {LastContent ? <LastContent /> : null}
      {ActionMenus && ActionMenus.length > 0 ? (
        <TableCell onClick={event => event.stopPropagation()}>
          <div className="m-1 dropdown">
            <div className="fab43-default action-btn" onClick={event => handleMenuClick(event)}>
              <i className="sir sir-icon-menu-more icon-medium" />
            </div>
            <Menu className="card-body" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {ActionMenus.map(action => (
                <MenuItem key={action.id} className="card-body-item" onClick={() => handleActionClick(action)}>
                  {action.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

const TableFooterContainer = props => {
  const { Count, handleChangePage, handleChangeRowsPerPage, product_page, rowsPerPage } = props;

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={Count}
          rowsPerPage={rowsPerPage}
          page={product_page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  );
};

const PaginationContainer = props => {
  const { Count, handleChangePage, product_page, rowsPerPage } = props;
  const page_count = Count > 0 ? Math.floor((Count - 1) / rowsPerPage) + 1 : 1;

  return (
    <Pagination count={page_count} page={product_page} onChange={handleChangePage} className="ml-auto" siblingCount={2} />
  );
};

const PageLoader = () => {
  const classes = customStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const BackToPrev = props => {
  const { title, handleBackClick } = props;
  return (
    <div
      className="d-flex w-100 justify-content-between align-items-center"
      style={{ cursor: 'pointer' }}
      onClick={() => handleBackClick()}>
      <div className="mb-3 text-white backlink">
        <i className="sir sir-icon-arrow-left"></i>
        {title ?? `Back`}
      </div>
    </div>
  );
};

const TabContainer = props => {
  const { TabRows, active_tab, setActiveTab } = props;
  return (
    <div className="mui-panel-top">
      <ul className="mui-tabs__bar mui-tabs__bar--justified custom-tabs">
        {TabRows.map(tab => (
          <li key={tab.id} className={tab.id == active_tab ? `mui--is-active` : ``} onClick={() => setActiveTab(tab.id)}>
            <a>
              <i className={tab.icon}></i>
              {tab.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const PageLoaderContainer = ({ children, className, restProps }) => {
  const classes = customStyles();
  const { page_loading } = useSelector(({ loader }) => loader);
  console.log('page loading: ', page_loading);
  return !page_loading ? (
    <Slide in={true} direction="up" mountOnEnter unmountOnExit>
      <Box className={clsx(classes.pageFull, className)} {...restProps}>
        {children}
      </Box>
    </Slide>
  ) : (
    <PageLoader />
  );
};

const EmptyImage = props => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stl-text-grey-400">
      <line x1="1" y1="1" x2="23" y2="23"></line>
      <path d="M21 21H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3m3-3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56"></path>
    </svg>
  );
};

const LoadingBtn = ({ loading, back_color, style, handleClick }) => {
  let bg_color = '#3f51b5'; //primary
  switch (back_color) {
    case 'green':
      bg_color = '#4caf50';
      break;
    case 'purple':
      bg_color = 'rgb(5, 57, 107)';
      break;
    case 'red':
      bg_color = '';
      break;
    default:
      bg_color = back_color
      break;
  }
  const classes = customStyles({ bg_color });
  return (
    <Button variant="contained" style={style} className={classes.loadingBtn} onClick={handleClick}>
      {loading ? (
        <>
          <i className="mr-1 zmdi zmdi-spinner zmdi-hc-spin font-4" />
          Saving...
        </>
      ) : (
        'Save'
      )}
    </Button>
  );
};

const MoreBtn = ({ loading, back_color, btnTitle, handleClick }) => {
  const classes = customStyles({ back_color });
  return (
    <Button variant="contained" className={classes.more_button} onClick={handleClick}>
      {loading &&
        <i className="mr-1 zmdi zmdi-spinner zmdi-hc-spin font-4" />
      } {btnTitle}
    </Button>
  );
};

export {
  TableHeadContainer,
  TableCellContainer,
  TableFooterContainer,
  PaginationContainer,
  PageLoader,
  BackToPrev,
  TabContainer,
  PageLoaderContainer,
  EmptyImage,
  LoadingBtn,
  MoreBtn,
  GreenSwitch,
  BlueOnGreenTooltip,
  BootstrapInput,
  StyledAvatar,
};
