import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { useDispatch } from 'react-redux';
import { pageFetchStart } from 'redux/actions';

import Error404 from '../404';

const ProjectPage = ({ match }) => {
  // const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path={`${match.url}`} component={lazy(() => import('./List'))} />
        <Route exact path={`${match.url}/:project_id`} component={lazy(() => import('./Detail'))} />
        <Route
          exact
          path={`${match.url}/room-image/sticker`}
          component={lazy(() => import('./Detail/ImageStickerContainer'))}
        />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default ProjectPage;
