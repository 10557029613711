import React, { useState } from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    paddingLeft: 12,
    paddingTop: 3,
    borderRadius: 4,
    background: 'white',
    paddingBottom: 3,
    paddingRight: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 13,
    bottom: 8,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const StyleTextField = withStyles({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
})(TextField);

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <StyleTextField
      fullWidth
      variant="outlined"
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      style={{ textOverflow: 'ellipsis', maxWidth: 120 }}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const IntegrationReactOutlineMultiSelect = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { label, suggestions, onSelect, value, isNotActive } = props;
  const [multi, setMulti] = useState(value);
  const [is_disabled, setIsDisabled] = useState(isNotActive ? true : false);
  function handleChangeMulti(value) {
    setMulti(value);
    onSelect(
      value == null
        ? []
        : value.map(item => {
          return item.value;
        }),
    );
  }
  // function handleChangeMulti(value) {
  //   setMulti(value);
  // }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          //inputId={single.label+single.value}
          isDisabled={is_disabled}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
            },
            placeholder: 'Search a country (start with a)',
          }}
          options={suggestions}
          components={components}
          value={multi}
          onChange={handleChangeMulti}
          isMulti
        />
      </NoSsr>
    </div>
  );
};

const IntegrationReactOutlineSelect = props => {
  const classes = useStyles();
  const theme = useTheme();

  //const [multi, setMulti] = React.useState(null);
  const { label, suggestions, onSelect, value, isNotActive } = props;

  const [single, setSingle] = useState(value);
  // const [is_disabled, setIsDisabled] = useState(isNotActive ? true : false);
  const [show_suggestions, setShowSuggestions] = useState(suggestions.filter((item, index) => index < 100));
  function handleChangeSingle(value) {
    setSingle(value);
    onSelect(value.value);
  }

  function handleInputChange(text) {
    setShowSuggestions(
      suggestions.filter(item => item.label.toLowerCase().includes(text.toLowerCase())).filter((item, index) => index < 100),
    );
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          //inputId={single.label+single.value}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
            },
            placeholder: 'Search a country (start with a)',
          }}
          isDisabled={isNotActive}
          options={show_suggestions}
          components={components}
          value={value ? single : null}
          onChange={handleChangeSingle}
          onInputChange={handleInputChange}
        />
      </NoSsr>
    </div>
  );
};

const IntegrationCustomReactSelect = props => {
  const classes = useStyles();
  const theme = useTheme();

  //const [multi, setMulti] = React.useState(null);
  const { label, suggestions, onSelect, value, isNotActive } = props;

  const [single, setSingle] = useState(value);
  const [show_suggestions, setShowSuggestions] = useState(suggestions.filter((item, index) => index < 100));
  // const [is_disabled, setIsDisabled] = useState(isNotActive ? true : false);

  function handleChangeSingle(value) {
    setSingle(value);
    onSelect(value.value);
  }

  function handleInputChange(text) {
    setShowSuggestions(
      suggestions.filter(item => item.label.toLowerCase().includes(text.toLowerCase())).filter((item, index) => index < 100),
    );
  }
  // function handleChangeMulti(value) {
  //   setMulti(value);
  // }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          //inputId={single.label+single.value}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
            },
            placeholder: 'Search a country (start with a)',
          }}
          menuPlacement="auto"
          menuPortalTarget={document.querySelector('body')}
          isDisabled={isNotActive}
          options={show_suggestions}
          components={components}
          value={value ? single : null}
          onChange={handleChangeSingle}
          onInputChange={handleInputChange}
        />
      </NoSsr>
    </div>
  );
};

export { IntegrationReactOutlineSelect, IntegrationReactOutlineMultiSelect, IntegrationCustomReactSelect };
