import { PAGE_FETCH_ERROR, PAGE_FETCH_START, PAGE_FETCH_SUCCESS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  page_loading: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAGE_FETCH_START: {
      return { ...state, error: '', message: '', page_loading: true };
    }
    case PAGE_FETCH_SUCCESS: {
      return { ...state, error: '', page_loading: false, message: action.payload };
    }
    case PAGE_FETCH_ERROR: {
      return { ...state, page_loading: false, message: '', error: action.payload };
    }
    default:
      return state;
  }
};
