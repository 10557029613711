import { $http, mobile_url, public_url, base_url, CACHE_NAME, local_ip } from 'config';
import { WHITE_BOARD } from 'constant/Constant';
const FOLDER_NAME = 'post_requests';
var our_db;

const cache_file_array = async files => {
  await $http.get(`${base_url}auth/me`);
  for (const file of files) {
    if (file.type == 'file') {
      if (Array.from(file.basename)[0] == '.') continue;
      if (file.path.includes('/.')) continue;
      await $http.get(`${mobile_url}${file.path}`);
    }
  }
};

const cache_project_array = async (projects, setProjectStatus) => {
  await $http.get(`${base_url}auth/me`);
  await $http.get(`${base_url}project/projectInfo`);
  // await $http.get(`${base_url}project/getprojectList`);
  await $http.get(`${base_url}room/editPhoto`);
  await $http.get(`${mobile_url}project/room-image/sticker`);
  await $http.get(`${mobile_url}company`);
  await $http.get(`${mobile_url}upload/img/${WHITE_BOARD}`);
  await $http.get(`${mobile_url}upload/img/whiteboard2.png`);

  let total_cnt = projects.length;
  console.log('cache projects==> ', projects);
  let cnt = 0;
  for (const project of projects) {
    let response = await $http.get(`${base_url}project/projectInfo?project_id=${project}`);

    cnt++;

    console.log('** response projectinfo', response.data);
    if (response.data.status == 'error') continue;
    setProjectStatus(`(${cnt}/${total_cnt}) ${response.data.project.project_name}`);

    await $http.get(`${mobile_url}project/${project}`);
    if (response.data.project.logo_img) {
      $http.get(`${public_url}upload/img/${response.data.project.logo_img}`);
      $http.get(`${mobile_url}upload/img/${response.data.project.logo_img}`);
    }

    await $http.get(`${base_url}product/handleGetProductList?project_id=${project}`);
    $http.get(`${base_url}room/roomInfo?project_id=${project}`);

    for (const room of response.data.rooms) {
      for (const img of room.img_files) {
        $http.get(`${public_url}upload/img/${img.img_name}`);
        $http.get(`${mobile_url}upload/img/${img.img_name}`);
      }

      await $http.get(`${base_url}room/roomInfo?project_id=${room.project_id}&id=${room.room_id}`);
      let product_res = await $http.get(
        `${base_url}product/handleGetProductList?room_id=${room.room_id}&project_id=${project}`,
      );

      for (const product of product_res.data.products) {
        $http.get(`${base_url}product/getProductInfo?id=${product.id}`);
      }
    }
  }
};

function is_existing_check(api_url) {
  if (window.location.hostname === local_ip) return;
  caches.open(CACHE_NAME).then(function(cache) {
    cache
      .match(api_url)
      .then(response => (response ? response.json() : null))
      .then(function(data) {
        if (data == null) return false;
        console.log('existed data', data);
        return true;
      });
  });
}

const cache_project_selected = async project => {
  $http.get(`${base_url}auth/me`);
  await $http.get(`${base_url}project/projectInfo`);
  $http.get(`${base_url}room/editPhoto`);
  $http.get(`${mobile_url}project/room-image/sticker`);
  $http.get(`${mobile_url}company`);
  $http.get(`${public_url}upload/img/${WHITE_BOARD}`);
  $http.get(`${mobile_url}upload/img/${WHITE_BOARD}`);
  $http.get(`${mobile_url}upload/img/whiteboard2.png`);

  let response = await $http.get(`${base_url}project/projectInfo?project_id=${project.id}`);

  if (response.data.status == 'error') return;

  $http.get(`${mobile_url}project/${project.id}`);
  if (response.data.project.logo_img) {
    $http.get(`${public_url}upload/img/${response.data.project.logo_img}`);
  }

  $http.get(`${base_url}product/handleGetProductList?project_id=${project.id}`);
  $http.get(`${base_url}room/roomInfo?project_id=${project.id}`);

  for (const room of response.data.rooms) {
    for (const img of room.img_files) {
      $http.get(`${public_url}upload/img/${img.img_name}`);
      $http.get(`${mobile_url}upload/img/${img.img_name}`);
    }

    $http.get(`${base_url}room/roomInfo?project_id=${room.project_id}&id=${room.room_id}`);
    let product_res = await $http.get(
      `${base_url}product/handleGetProductList?room_id=${room.room_id}&project_id=${project.id}`,
    );
    for (const product of product_res.data.products) {
      $http.get(`${base_url}product/getProductInfo?id=${product.id}`);
    }
  }
};

openDatabase();

function openDatabase() {
  // if `flask-form` does not already exist in our browser (under our site), it is created
  var indexedDBOpenRequest = indexedDB.open('flask-form');

  indexedDBOpenRequest.onerror = function(error) {
    // errpr creatimg db
    //console.error('IndexedDB error:', error)
  };

  indexedDBOpenRequest.onupgradeneeded = function() {
    // This should only execute if there's a need to create/update db.
    this.result.createObjectStore(FOLDER_NAME, {
      autoIncrement: true,
      keyPath: 'id',
    });
  };

  // This will execute each time the database is opened.
  indexedDBOpenRequest.onsuccess = function() {
    our_db = this.result;
  };
}

function getObjectStore(storeName, mode) {
  return our_db.transaction(storeName, mode).objectStore(storeName);
}

const upload_offline_data = (failedMSG, successMSG, setPushSavedRequests, setDoneRequests, setFailedRequests) => {
  var savedRequests = [];
  var req = getObjectStore(FOLDER_NAME).openCursor(); // FOLDERNAME = 'post_requests'

  req.onsuccess = async function(event) {
    var cursor = event.target.result;
    console.log('send start **', cursor);
    if (cursor) {
      // Keep moving the cursor forward and collecting saved requests.
      savedRequests.push(cursor.value);
      cursor.continue();
    } else {
      // At this point, we have collected all the post requests in indexedb.
      for (let savedRequest of savedRequests) {
        // send them to the server one after the other
        var requestUrl = savedRequest.url;
        var payload = savedRequest.payload;
        var method = savedRequest.method;
        var headers = savedRequest.headers;
        var formData = new FormData();
        console.log('sending **', requestUrl, payload, formData);
        setPushSavedRequests(savedRequest);
        for (var key in payload) formData.append(key, payload[key]);
        await fetch(requestUrl, {
          headers: headers,
          method: method,
          body: formData,
        })
          .then(function(result) {
            return result.json();
          })
          .then(function(response) {
            setDoneRequests(savedRequest);
            getObjectStore(FOLDER_NAME, 'readwrite').delete(savedRequest.id);
          })
          .catch(function(error) {
            setFailedRequests(savedRequest);
          });
      }
    }
  };
};

const get_offline_data = setPushSavedRequests => {
  var savedRequests = [];
  var req = getObjectStore(FOLDER_NAME).openCursor(); // FOLDERNAME = 'post_requests'

  req.onsuccess = async function(event) {
    var cursor = event.target.result;
    console.log('send start **', cursor);
    if (cursor) {
      // Keep moving the cursor forward and collecting saved requests.
      setPushSavedRequests(cursor.value);
      cursor.continue();
    }
  };
};

const getExplanation = req => {
  if (req.url.includes('updateProject')) {
    return `Create a new Project [${req.payload.project_name}(#${req.payload.off_id})]`;
  } else if (req.url.includes('updateRoom')) {
    return `Create a new Location [${req.payload.room_number}(#${req.payload.id})]`;
  } else if (req.url.includes('AddLocationForm')) {
    return `Add a survey form on Location(#${req.payload.room_id})`;
  } else if (req.url.includes('addRoomImgMobile')) {
    return `Add an image on Location(#${req.payload.room_id})`;
  } else if (req.url.includes('changeRoomImage')) {
    return `Change an image on Location`;
  } else if (req.url.includes('changeNotes')) {
    return `Change a notes on Location(#${req.payload.id})`;
  } else if (req.url.includes('addRoomEmptyImage')) {
    return `Add an white blank image on Location(#${req.payload.room_id})`;
  }
  return req.url;
};

export {
  cache_file_array,
  cache_project_array,
  cache_project_selected,
  is_existing_check,
  upload_offline_data,
  get_offline_data,
  getExplanation,
};
