import React from 'react';
import { PostAdd, BusinessCenter, DateRange, Scanner, Group } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  // {
  //   name: <IntlMessages id={'sidebar.main'} />,
  //   type: 'collapse',
  //   children: [
  //     {
  //       name: <IntlMessages id={'pages.samplePage'} />,
  //       type: 'item',
  //       icon: <PostAdd />,
  //       link: '/sample-page',
  //     },
  //   ],
  // },
  {
    name: 'Projects',
    type: 'mega',
    icon: <i className="sir sir-icon-projects nav-icon" />,
    link: '/project',
  },
  {
    name: 'Calendar',
    type: 'mega',
    icon: <i className="sir sir-icon-calendar nav-icon" />,
    link: '/calendar',
  },
  {
    name: 'Scan',
    type: 'mega',
    icon: <img src="/assets/images/scan.svg" className="mt-1 mb-2" width="28" />,
    link: '/scan',
  },
  {
    name: 'Customers',
    type: 'mega',
    icon: <i className="sir sir-icon-company nav-icon" />,
    link: '/company',
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
