import React, { useState, useEffect } from 'react';
import { getResponsePWA, getProductImgUrl, getUserImgUrl, handleOpenFile } from 'constant/Func';
import { GreenSwitch } from 'constant/Component';
import { TaskPriorityRows, keyDateRows, dataPointRows } from 'constant/Constant';
import Moment from 'moment';
import {
  TextField,
  Dialog,
  useMediaQuery,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import { useTheme, alpha } from '@material-ui/core/styles';
import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { $http, base_url } from 'config';
import { QRCode } from 'react-qrcode-logo';
import { useParams } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { cache_product_change_name } from 'constant/CacheManage/Product';
import { OFFLINE_POST_MSG } from 'constant/Constant';
import { useDispatch } from 'react-redux';
import { fetchError, fetchSuccess } from 'redux/actions';
import ReactPlayer from 'react-player/lazy';
import { IntegrationReactOutlineSelect } from 'constant/ReactSelect';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { public_url } from 'config';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ProductDetail = props => {
  const { product, handleAllProductsClick, test_form, all_users, product_classes } = props;
  const [openTestForm, setOpenTestForm] = useState(false);
  const [task_priority, setTaskPriority] = useState(1);
  const [Product, setProduct] = useState(product);
  const [mark_as_complete, setMarkAsComplete] = useState(false);
  const [edit_point, setEditPoint] = useState(false);
  const [activities, setActivities] = useState([]);
  const [addTestForm, setAddTestForm] = useState(false);
  const [TestForm, setTestForm] = useState(test_form);
  const product_class = product && product_classes ? product_classes.find(cls => cls.id == product.class_id) : {};
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const [test_forms, setTestForms] = useState([]);
  const [form_fields, setFormFields] = useState([]);
  const [save_loader, setSaveLoader] = useState(false);
  const [test_values, setTestValues] = useState([]);

  const [is_create_task, setIsCreateTask] = useState(false);

  const [test_img, setTestImg] = useState(null);
  const [test_img_url, setTestImgUrl] = useState(null);
  const [test_video_url, setTestVideoUrl] = useState(null);
  const [test_video, setTestVideo] = useState(null);
  const [task_duebydate, setTaskDuebydate] = useState(new Date(Moment(new Date()).add(1, 'days')));
  const [test_user, setTestUser] = useState(
    all_users && test_form ? all_users.find(user => user.id == test_form.user_id) : {},
  );

  const [task_notes, setTaskNotes] = useState('');

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [product_detail, setProductDetail] = useState({});

  const [edit_title, setEditTitle] = useState(false);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    getResponsePWA(
      `${base_url}product/getProductInfo?id=${product ? product.id : params.product_id}`,
      updateNetworkResponse,
    );

    // $http.get(`${base_url}product/getProductInfo?id=${product ? product.id : params.product_id}`).then(response => {
    //   updateNetworkResponse(response.data);
    // });
  }, []);

  const updateNetworkResponse = data => {
    if (data.status == 'success') {
      setProduct(data.product);
      setActivities(data.product.activities);
      if (!product_classes) {
        product_class = data.product_classes.find(cls => cls.id == Product.class_id);
      }

      if (data.test_user) {
        setTestUser(data.test_user);
      }
      setTestValues(data.test_values);
      setTestForms(data.test_forms);
      setFormFields(data.form_fields);

      if (data.test_forms.find(item => item.id == data.product.test_form_id))
        setTestForm(data.test_forms.find(item => item.id == data.product.test_form_id));
    }
  };

  const getTestFormName = () => {
    if (TestForm) return TestForm.form_name;
    return 'NONE';
  };

  const handleTestFormClick = () => {
    if (Product.test_form_id > 0) setTestForm(test_forms.find(item => item.id == Product.test_form_id));
    if (Product.testing_img) setTestImgUrl(`${public_url}upload/img/${Product.testing_img}`);
    if (Product.testing_video) setTestVideoUrl(`${public_url}upload/img/${Product.testing_video}`);
    setOpenTestForm(true);
  };

  const handleTestFormClose = () => {
    setOpenTestForm(false);
  };

  const handleUploadImg = event => {
    let file = event.target.files[0];
    setTestImg(file);

    setTestImgUrl(URL.createObjectURL(file));
  };

  const handleUploadVideo = event => {
    let file = event.target.files[0];
    setTestVideo(file);
    setTestVideoUrl(URL.createObjectURL(file));
  };

  const handleTaskPriorityClick = priority => {
    setTaskPriority(priority);
  };

  const handleMarkAsComplete = () => {
    setMarkAsComplete(!mark_as_complete);
  };

  const TaskPriority = () => {
    switch (task_priority) {
      case 1:
        return 3;

      case 2:
        return 2;
      case 3:
        return 1;
      case 4:
        return 0;
      default:
        break;
    }
  };

  const handleChangePoint = () => {
    if (edit_point) {
      const frmData = new FormData();
      frmData.append('id', Product.id);
      frmData.append('points', JSON.stringify(points));
      frmData.append('stage', 2);

      if (!navigator.onLine) {
        cache_product_change_name(Product.id, points, 2);
        dispatch(fetchError(OFFLINE_POST_MSG));

        let Commissions = Product.commissions;
        let _commissions = points.map(item => {
          let obj = {};
          if (Commissions.find(com => com.label == item.id)) obj = Commissions.find(com => com.label == item.id);
          else {
            // obj.id = item.id + '_' + new Date().getTime();
            obj.id = item.id;
            obj.edit = 0;
            obj.is_require = item.value ? 1 : 0;
            obj.label = item.id
            obj.product_id = Product.id;
            let title = dataPointRows.find(pot => pot.value == item.id).label;
            obj.title = title;
          }
          obj.value = item.value;
          return obj;
        });

        console.log("points123: ", _commissions)

        setProduct({
          ...Product,
          commissions: [..._commissions]
        })
      }
      $http
        .post(`${base_url}product/onChangeProductMobile`, frmData)
        .then(response => {
          if (response.data.status === 'success') {
            dispatch(fetchSuccess('Updated Successfully'));
            setProduct({
              ...Product,
              commissions: [...response.data.commissions],
            });
          }
        })
        .catch(error => {
          dispatch(fetchError(error));
        });
    } else {
      let Commissions = Product.commissions;
      let Points = dataPointRows.map(item => {
        return {
          id: item.value,
          value: Commissions.find(com => com.label == item.value)
            ? Commissions.find(com => com.label == item.value).value
            : null,
        };
      });

      setPoints([...Points]);
    }

    setEditPoint(!edit_point);
  };

  const saveAddTestForm = () => {
    const frmData = new FormData();
    frmData.append('id', Product.id);
    frmData.append('test_form_id', Product.test_form_id);

    $http
      .post(`${base_url}product/addTestForm`, frmData)
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(fetchSuccess('Added Successfully'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error));
      });
    setAddTestForm(false);
  };

  const handleChangeDataPoint = event => {
    console.log('id: ', event.target.name);
    console.log('value: ', event.target.value);
    let Points = points.map(item => {
      if (item.id == event.target.name) return { ...item, value: event.target.value };
      else return item;
    });

    console.log('points: ', Points);
    setPoints([...Points]);
  };

  const handleChangeTitle = () => {
    if (edit_title) {
      const frmData = new FormData();
      frmData.append('id', Product.id);
      frmData.append('product_detail', JSON.stringify(product_detail));
      frmData.append('stage', 1);

      if (!navigator.onLine) {
        cache_product_change_name(Product.id, product_detail, 1);
        dispatch(fetchError(OFFLINE_POST_MSG));
      }

      $http
        .post(`${base_url}product/onChangeProductMobile`, frmData)
        .then(response => {
          if (response.data.status === 'success') {
            dispatch(fetchSuccess('Updated Successfully'));
            setProduct({
              ...Product,
              product_name: product_detail.product_name,
              description: product_detail.summary,
              model_number: product_detail.model_number,
            });
          }
        })
        .catch(error => {
          dispatch(fetchError(error));
        });
    } else {
      setProductDetail({
        product_name: Product.product_name,
        summary: Product.description ? Product.description : '',
        model_number: Product.model_number ? Product.model_number : '',
        // manufacturer: Product.manufacturer
      });
    }
    setEditTitle(!edit_title);
  };

  const handleChangeProductDetail = event => {
    setProductDetail({
      ...product_detail,
      [event.target.name]: event.target.value,
    });
  };

  const is_only_show = _field_name => {
    let form_data = Product.test_form.form_data;
    let component = JSON.parse(form_data).find(form_data => form_data.field_name == _field_name);
    if (component && component.canDisplayHorizonal && component.onlyShowIfOption) {
      let dropdown = test_values.find(
        value => value.field_name == component.product_dropdown && value.field_value == component.product_dropdown_option,
      );
      if (dropdown) {
        return is_only_show(dropdown.field_name);
      }
      return false;
    }
    return true;
  };

  const saveTestingForm = () => {
    setSaveLoader(true);
    let values = [];
    var value = '';
    let field_names = form_fields.filter(item => item.new_form_id == Product.test_form_id);
    values = field_names.map(item => {
      var is_checked = '';
      if (item.field_type == 'RadioButtons' || item.field_type == 'Checkboxes') {
        is_checked = document.getElementById(`fid_preview_${item.field_name}`).checked;
        value = document.getElementById(`fid_preview_${item.field_name}`).value;
      } else if (item.field_type == 'Signature') {
        value = document.querySelector('.react-form-builder-form canvas').toDataURL();
      } else value = document.getElementsByName(`${item.field_name}`)[0].value;
      return {
        field_name: item.field_name,
        field_label: item.field_label,
        field_type: item.field_type,
        field_value: value,
        form_type: item.form_type,
        is_checked: is_checked,
        new_form_id: Product.test_form_id,
      };
    });

    const frmData = new FormData();
    frmData.append('id', Product.id);
    frmData.append('test_form_id', Product.test_form_id);
    frmData.append('test_values', JSON.stringify(values));
    frmData.append('testing_img', test_img);
    frmData.append('testing_video', test_video);
    frmData.append('is_task', is_create_task ? 1 : 0);
    // frmData.append('assign_to', JSON.stringify(task_assign));
    frmData.append('due_by_date', Moment(task_duebydate).format('YYYY-MM-DD'));
    frmData.append('notes', task_notes);
    frmData.append('snagging', TaskPriority());
    frmData.append('is_project', 1);
    $http
      .post(`${base_url}product/saveTestingForm`, frmData)
      .then(response => {
        if (response.data.status === 'success') {
          dispatch(fetchSuccess('You updated testing form!'));
          setTestValues([...values]);
          setProduct({
            ...Product,
            test_form: response.data.test_form,
          });
          setSaveLoader(false);
          setOpenTestForm(false);
        }
      })
      .catch(error => {
        dispatch(fetchError(error));
        setOpenTestForm(false);
      });
  };

  const toggleIsCreateTask = event => {
    setIsCreateTask(event.target.checked);
  };

  return Product ? (
    <div className="mui-panel mt-2">
      <div className="mui-panel-content row">
        {handleAllProductsClick ? (
          <div className="text-link backlink mb-2" onClick={() => handleAllProductsClick()}>
            <i className="siz siz-icon-arrow-up icon-large" />
            All Products
          </div>
        ) : null}
        <div className="mui-panel-transparent container mt-2">
          <div className="row">
            <div className="col-4">
              <div className="img-panel d-relative">
                <figure>
                  <img src={getProductImgUrl(Product)} />
                </figure>
              </div>
            </div>

            <div className="col-8">
              <div>
                <div className="d-flex">
                  {edit_title ? (
                    <TextField
                      margin="dense"
                      label="product name"
                      value={product_detail.product_name}
                      onChange={handleChangeProductDetail}
                      name="product_name"
                      fullWidth
                    />
                  ) : (
                    <h3 className="text-truncate">{Product.product_name}</h3>
                  )}
                  <div className="fab33 ml-3 cursor-pointer" onClick={handleChangeTitle}>
                    <i className={edit_title ? 'zmdi zmdi-check' : 'sir sir-icon-edit'} />
                  </div>
                </div>
                <span className="id mr-2">#{Product.id}</span>
              </div>
              <div className="mr-4 mt-4">
                <div className="product-description">
                  {edit_title ? (
                    <TextField
                      margin="dense"
                      label="description"
                      value={product_detail.summary}
                      onChange={handleChangeProductDetail}
                      name="summary"
                      fullWidth
                    />
                  ) : (
                    <p>{Product.description}</p>
                  )}
                </div>
                <ul className="list-info company">
                  <li>
                    <span className="d-flex">Model: </span>
                    {edit_title ? (
                      <TextField
                        margin="dense"
                        value={product_detail.model_number}
                        onChange={handleChangeProductDetail}
                        name="model_number"
                        fullWidth
                      />
                    ) : (
                      <strong>{Product.model_number}</strong>
                    )}
                  </li>
                  <li>
                    <span className="d-flex">Manufacturer:</span>
                    <strong>{Product.manufacturer_name}</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="mui-panel mui-panel-grey d-flex justify-content-center mr-4">
              <div className="mui-panel-content">
                <QRCode
                  value={JSON.stringify({
                    app_name: 'sirvez',
                    product_id: Product.id,
                    url: `project/live/${Product.project_id}/${Product.room_id}/${Product.group_id}/${Product.id}`,
                  })}
                  logoImage={require('assets/images/logo.png')}
                  size={160}
                  logoWidth={160 * 0.2}
                  logoHeight={160 * 0.2}
                  level={'H'}
                  logoOpacity="1"
                  includeMargin={true}
                />
              </div>
            </div>

            {/*  <div className="col-md-6"> */}
            <div className="mui-panel mui-panel-border" style={{ minWidth: 200 }}>
              <div className="mui-panel-content">
                <div className="inline-middle mb-2">
                  <h5>Testing Form</h5>
                  {Product.test_form_id == 0 ? (
                    <i
                      className="cursor-pointer text-green mb-2 ml-auto edit-point font-4 sir sir-icon-plus"
                      onClick={() => setAddTestForm(true)}
                    />
                  ) : null}
                </div>

                <ul className="list-info items">
                  <li>
                    <span className="d-flex">Testing form: </span>
                    <strong>{getTestFormName()}</strong>
                  </li>
                </ul>

                {Product.test_form_id > 0 ? (
                  <div className="mui-btn mui-btn--primary w-100" onClick={handleTestFormClick}>
                    <i className="siz siz-icon-location-survey icon-medium"></i>View Testing Form
                  </div>
                ) : null}

                {Product.test_form_id > 0 && test_values.length > 0 ? (
                  <div className="mt-4">
                    <ul className="list-info">
                      {test_values
                        .filter(item => is_only_show(item.field_name))
                        .map(item => JSON.stringify({ field_label: item.field_label, field_type: item.field_type }))
                        .filter((item, index, arr) => arr.indexOf(item) === index)
                        .map((value, index) => {
                          let item = JSON.parse(value);
                          return (
                            <li key={index}>
                              <span className="mr-3">{item.field_label}</span>
                              {item.field_type === 'Signature' ? (
                                <img
                                  className="w-100 h-auto"
                                  src={test_values
                                    .filter(value => {
                                      return item.field_label === value.field_label && item.field_type === value.field_type;
                                    })
                                    .map(value => {
                                      return value.field_value;
                                    })}
                                />
                              ) : (
                                <strong>
                                  {test_values
                                    .filter(value => {
                                      return (
                                        item.field_label === value.field_label &&
                                        item.field_type === value.field_type &&
                                        (value.is_checked == '1' ||
                                          (value.field_type !== 'Checkboxes' && value.field_type !== 'RadioButtons'))
                                      );
                                    })
                                    .map(value => {
                                      return value.field_value;
                                    })
                                    .join(', ')}
                                </strong>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                ) : null}

                <Modal className="modal-box" toggle={() => setAddTestForm(false)} isOpen={addTestForm}>
                  <ModalHeader style={{ backgroundColor: '#05396b' }}>
                    <span className="text-white">Testing Form</span>
                  </ModalHeader>
                  <ModalBody>
                    <IntegrationReactOutlineSelect
                      suggestions={test_forms.map(item => {
                        return {
                          value: item.id,
                          label: item.form_name,
                        };
                      })}
                      fullWidth
                      value={
                        test_forms.find(item => item.id == Product.test_form_id)
                          ? test_forms
                            .filter(item => item.id == Product.test_form_id)
                            .map(item => {
                              return {
                                value: item.id,
                                label: item.form_name,
                              };
                            })
                          : null
                      }
                      onSelect={val => {
                        setProduct({
                          ...Product,
                          test_form_id: val,
                        });
                        setTestForm(test_forms.find(item => item.id == val));
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="contained" color="primary" onClick={saveAddTestForm}>
                      Save
                    </Button>
                  </ModalFooter>
                </Modal>

                <Dialog fullScreen={fullScreen} open={openTestForm} onClose={handleTestFormClose}>
                  <div className="modal-content mui-panel-content p-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>Testing Form</h5>
                        <span className="date">#{Product.test_form_id}</span>
                      </div>
                      <div className="d-flex">
                        <div className="mui-btn" onClick={() => handleMarkAsComplete()}>
                          {mark_as_complete ? <i className="sir sir-icon-tick text-normal mr-2"></i> : null}
                          Mark as Complete
                        </div>

                        <button className="mui-btn mui-btn--primary" onClick={saveTestingForm}>
                          {save_loader ? (
                            <>
                              <i className="zmdi zmdi-hc-spin zmdi-spinner" /> Saving{' '}
                            </>
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </div>
                    {TestForm ? <ReactFormGenerator hide_actions data={JSON.parse(TestForm.form_data)} /> : null}
                    <form>
                      <div className="d-flex upload my-3">
                        <label className="file d-flex align-items-center mr-2">
                          <figure className="add-new-img mr-2">
                            <i className="sir sir-icon-camera icon-large" />
                          </figure>
                          {test_img_url ? (
                            <div className="testing-file">
                              <img className="h-100" src={test_img_url} />
                            </div>
                          ) : (
                            'Upload Image...'
                          )}
                          <input type="file" multiple="" onChange={handleUploadImg} />
                        </label>

                        <label className="file d-flex  align-items-center">
                          <figure className="add-new-img mr-2">
                            <i className="siz siz-icon-video icon-large"></i>
                          </figure>
                          {test_video_url ? (
                            <div className="testing-file">
                              <ReactPlayer
                                url={test_video_url}
                                className="react-player"
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </div>
                          ) : (
                            'Upload Video...'
                          )}
                          <input type="file" multiple="" onChange={handleUploadVideo} />
                        </label>
                      </div>

                      <h4 className="mb-0 mt-4">Create Task</h4>
                      <div className="form-outline-switch d-flex">
                        <div className="form-check form-switch pl-0">
                          <FormControlLabel
                            control={<GreenSwitch checked={is_create_task} onChange={toggleIsCreateTask} />}
                            label="Custom color"
                          />
                        </div>
                      </div>
                      {is_create_task ? (
                        <div className="mui-panel-grey p-42">
                          <div className="mui-panel-content">
                            <div className="d-flex align-items-center my-2">
                              <div className="avatar mr-2">
                                <img src={getUserImgUrl(test_user)} />
                              </div>
                              <h4>
                                {getTestFormName()} #{Product.test_form_id} Test Form Task
                              </h4>
                            </div>

                            <div className="form-outline d-flex">
                              <div className="toggler w-100 my-2">
                                <div
                                  className="toggler-priority"
                                  style={{ transform: `translateX(calc(${(task_priority - 1) * 100}%))` }}></div>
                                {TaskPriorityRows.map(priority_row => (
                                  <label
                                    key={priority_row.id}
                                    className={`${priority_row.id == task_priority ? 'selected' : ''}`}
                                    onClick={() => handleTaskPriorityClick(priority_row.id)}>
                                    <div>
                                      <span className={`circle ${priority_row.cn} mr-2`}></span>
                                      {priority_row.label}
                                    </div>
                                  </label>
                                ))}
                              </div>
                            </div>

                            <div className="form-outline my-2">
                              <textarea
                                className="form-control"
                                id="textAreaExample"
                                rows="2"
                                style={{ border: 'solid 1px #ced4da' }}
                                value={task_notes}
                                onChange={event => setTaskNotes(event.target.value)}
                              />
                              <label className="form-label" htmlFor="textAreaExample" style={{ marginLeft: 0 }}>
                                Notes
                              </label>
                            </div>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                label="Due By Date"
                                format="dd/MM/yyyy"
                                value={task_duebydate}
                                fullWidth
                                className="mb-3"
                                onChange={setTaskDuebydate}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
          <div className="row w-100 align-items-start mt-3">
            <div className="col-md-4">
              <div className="mui-panel mui-panel-border">
                <div className="mui-panel-content">
                  <ul className="list-info company">
                    <li>
                      <h5>Key DATES</h5>
                    </li>
                    {keyDateRows.map(key_date => (
                      <li key={key_date.id}>
                        <span className="d-flex">{key_date.label}: </span>
                        <strong>
                          {key_date.value ? Moment(new Date(Product[key_date.value])).format('Do MMM YYYY') : ''}
                        </strong>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mui-panel mui-panel-border">
                <div className="mui-panel-content">
                  <ul className="list-info company">
                    <li>
                      <h5>Data Points</h5>
                      <i
                        className={`cursor-pointer mb-2 ml-auto edit-point font-4 ${edit_point ? 'zmdi zmdi-check' : 'sir sir-icon-edit'
                          }`}
                        onClick={handleChangePoint}
                      />
                    </li>
                    {dataPointRows.map(data_point => (
                      <li key={data_point.id}>
                        <span className="d-flex">{data_point.label}: </span>
                        {edit_point ? (
                          <TextField
                            value={
                              points.find(item => item.id == data_point.value) &&
                                points.find(item => item.id == data_point.value).value
                                ? points.find(item => item.id == data_point.value).value
                                : ''
                            }
                            onChange={handleChangeDataPoint}
                            name={data_point.value}
                          />
                        ) : (
                          <strong>
                            {Product.commissions.find(com => com.label == data_point.value)
                              ? Product.commissions.find(com => com.label == data_point.value).value
                              : ''}
                          </strong>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mui-panel mui-panel-border">
                <div className="mui-panel-content">
                  <h5>Product Data</h5>
                  <span className="date">Technical Info</span>
                  <ul className="list-info items icons-frame ">
                    {Product.technical_pdf ? (
                      <li>
                        <span className="d-flex" onClick={() => handleOpenFile(Product.technical_pdf)}>
                          <i className="sir sir-icon-pdf"></i>
                        </span>
                        <strong>{Product.technical_pdf}</strong>
                      </li>
                    ) : null}
                    {Product.brochures_pdf ? (
                      <li>
                        <span className="d-flex" onClick={() => handleOpenFile(Product.brochures_pdf)}>
                          <i className="sir sir-icon-pdf"></i>
                        </span>
                        <strong>{Product.brochures_pdf}</strong>
                      </li>
                    ) : null}
                  </ul>
                  <span className="date">Filters</span>
                  <ul className="list-info">
                    <li>{product_class ? product_class.class_name : ''}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-100 justify-content-center mt-3">
            <div className="col-md-12">
              <div className="mui-panel mui-panel-border">
                <div className="mui-panel-content">
                  <h5>Activities</h5>
                  <div className="activities">
                    {activities.map(activity => (
                      <div className="activity feed-line d-flex" key={activity.id}>
                        <div className="avatar-with-badge">
                          <div className="avatar">
                            <img src={getUserImgUrl(activity)} />
                          </div>
                          <div className="badge taskbg">
                            <i className="sir sir-icon-projects"></i>
                          </div>
                        </div>
                        <div className="activity-text">
                          {activity.notification}
                          <div className="date">{Moment(activity.created_date).format('Do MMM YYYY')}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <PageLoader />
  );
};

export default ProductDetail;
