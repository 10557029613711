import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageFetchLoader from '@jumbo/components/PageFetchLoader';
import Error404 from '../404';
import { useDispatch } from 'react-redux'
import { pageFetchStart } from 'redux/actions';

const CompanyPage = ({ match }) => {
  // const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageFetchLoader />}>
      <Switch>
        <Route exact path={`${match.url}`} component={lazy(() => import('./List'))} />
        <Route exact path={`${match.url}/:customer_id`} component={lazy(() => import('./Detail'))} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default CompanyPage;
